<div class="modal-body">
  <button (click)="onHandleClose()" aria-label="Close" class="close" id="edit-hcp-modal-close" data-dismiss="modal"
          type="button">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.edit_hcp.title' | translate }}</h2>

  <form *ngIf="form" [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">

    <!-- Gender -->
    <div class="form-group pb-2">
      <fieldset>
        <legend class="small d-block required">{{ 'form.labels.gender' | translate }}</legend>

        <div class="form-check">
          <input class="form-check-input" formControlName="gender" id="radioSexMale" type="radio" value="MALE">
          <label class="form-check-label" for="radioSexMale">{{ 'form.labels.male' | translate }}</label>
        </div>

        <div class="form-check">
          <input class="form-check-input" formControlName="gender" id="radioSexFemale" type="radio" value="FEMALE">
          <label class="form-check-label" for="radioSexFemale">{{ 'form.labels.female' | translate }}</label>
        </div>

        <div class="form-check">
          <input class="form-check-input" formControlName="gender" id="radioSexOther" type="radio" value="UNKNOWN">
          <label class="form-check-label" for="radioSexOther">{{ 'form.labels.other' | translate }}</label>
        </div>

      </fieldset>

      <app-feedback-field [field]="form.get('gender')"/>
    </div>

    <!-- First name -->
    <div class="form-group styled floating-label">
      <input [placeholder]="('form.labels.first_name' | translate)" class="form-control w-100"
             formControlName="first_name"
             id="inputFirstName" type="text">
      <label class="required" for="inputFirstName">{{ 'form.labels.first_name' | translate }}</label>
      <app-feedback-field [field]="form.get('first_name')"></app-feedback-field>
    </div>

    <!-- Last name -->
    <div class="form-group styled floating-label">
      <input [placeholder]="'.form.labels.last_name' | translate" class="form-control w-100" formControlName="last_name"
             id="inputLastName" type="text">
      <label class="required" for="inputLastName">{{ 'form.labels.last_name' | translate }}</label>
      <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
    </div>

    <!-- Phone number -->
    <div class="row">
      <div class="col-5" [ngClass]="{'order-last': languageService.isHebrew}">
        <div class="form-group styled floating-label">
          <input [placeholder]="('form.labels.country_code' | translate)" class="form-control w-100"
                 formControlName="phoneNumberCountryCode" id="inputPhoneCode"
                 type="tel">
          <label for="inputPhoneCode">{{ 'form.labels.country_code' | translate }}</label>
        </div>
      </div>
      <div class="col-7" [ngClass]="{'order-first': languageService.isHebrew}">
        <div class="form-group styled floating-label">
          <input [placeholder]="('form.labels.phone_number' | translate)" class="form-control w-100"
                 formControlName="phoneNumber" id="inputPhone" type="tel">
          <label for="inputPhone">{{ 'form.labels.phone_number' | translate }}</label>
        </div>
      </div>
    </div>

    <!-- Email -->
    <div class="row mb-2">
      <div class="col">
        <div class="form-group styled floating-label mb-0">
          <input type="email" id="inputEmail" class="form-control w-100"
                 [placeholder]="('form.labels.email' | translate)" formControlName="email">
          <label for="inputEmail">{{ 'form.labels.email' | translate }}</label>
        </div>
      </div>
      <div class="col-auto" *ngIf="hcp?.email_change_allowed">
        <button class="btn btn-link h-100" (click)="showEditEmailModal()"
                id="edit-hcp-email">{{ 'modals.edit_hcp.edit_email' | translate }}
        </button>
      </div>
    </div>

    <!-- Language -->
    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.language' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="languageOptions"
        [bindValue]="'value'"

        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"

        formControlName="language"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
      <app-feedback-field [field]="form.get('language')"></app-feedback-field>
    </div>

    <!-- Function -->
    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.function' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="functionOptions"
        [bindValue]="'value'"

        formControlName="function"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
    </div>

    <!-- Job title -->
    <div class="form-group styled floating-label">
      <input [placeholder]="'.form.labels.job_title' | translate" class="form-control w-100"
             formControlName="job_title" id="jobTitle" type="text">
      <label for="jobTitle" class="required">{{ 'form.labels.job_title' | translate }}</label>
    </div>

    <!-- Location -->
    <div class="form-group styled floating-label">
      <input [placeholder]="'.form.labels.location' | translate" class="form-control w-100" formControlName="location"
             id="inputLocation" type="text">
      <label for="inputLocation">{{ 'form.labels.location' | translate }}</label>
    </div>

    <!-- Checkbox: use location URL -->
    <div class="form-group styled floating-label mb-0">
      <div class="form-check">
        <input class="form-check-input" formControlName="useLocationUrl" id="checkboxUseLocationUrl"
               type="checkbox">
        <label class="form-check-label"
               for="checkboxUseLocationUrl">{{ 'modals.edit_hcp.include_location_url' | translate }}</label>
      </div>
    </div>

    <!-- Location URL -->
    <div *ngIf="form.get('useLocationUrl').value" class="form-group styled floating-label">
      <input [placeholder]="'.form.labels.location_url' | translate" class="form-control w-100"
             formControlName="locationUrl"
             id="inputLocationUrl"
             type="text">
      <label for="inputLocationUrl">{{ 'form.labels.location_url' | translate }}</label>
    </div>
  </form>
</div>


<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link"
            id="edit-hcp-modal-cancel">{{ 'action.cancel' | translate }}
    </button>

    <button (click)="onHandleSubmit()" id="edit-hcp-modal-submit" [ngClass]="{'loader': isSaving}"
            class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
