<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-back"></div>

  <div class="layout-header header-back">
    <div class="header-content">
      <div class="container">
        <app-breadcrumb></app-breadcrumb>
      </div>

      <div class="container">

        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h5>{{ 'pages.default.administration.patients.patients' | translate }}</h5>
          </div>
          <div class="col col-auto">
            <div dropdown placement="top right" class="dropdown d-inline-block m-end-1" >
              <button id="button-patient-more-actions" dropdownToggle type="button" class="btn btn-icon btn-primary"
                      [ngClass]="{'disabled' : (selectedPatientUids?.length === 0)}"
                      aria-controls="dropdown-task-more-actions">
                <span [inlineSVG]="'/assets/svg/more-24.svg'"></span>
              </button>
              <ul id="dropdown-task-more-actions" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-task-more-actions">
                <li role="menuitem">
                  <a id="admin-patients-assign-mdt" class="dropdown-item" href="" (click)="onBulkAssignMdt($event)">{{ 'pages.default.administration.patients.bulk_assign_mdt' | translate }}</a>
                </li>
                <li role="menuitem">
                  <a id="admin-patients-edit-cl" class="dropdown-item" href="" (click)="onEditCmCL($event, 'CL')">{{ 'pages.default.administration.patients.edit_cl' | translate }}</a>
                </li>
                <li role="menuitem">
                  <a id="admin-patients-edit-cm" class="dropdown-item" href="" (click)="onEditCmCL($event, 'CM')">{{ 'pages.default.administration.patients.edit_cm' | translate }}</a>
                </li>
              </ul>
            </div>

            <button (click)="onAddNewPatient($event)"
                    [disabled]="selectedPatientUids?.length !== 0"
                    class="btn btn-primary"
                    id="admin-patients-add-new-patient"
                    href="">
              {{ 'pages.default.administration.patients.add_new_patient' | translate }}
            </button>
          </div>
        </div>

      </div>
    </div>

  </div>

  <div class="body-content">
    <div class="container pb-4">

      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">

          <div class="filters-group">

            <div class="form-group">
              <label for="patientLastNameSearch">{{ 'form.labels.patient_last_name' | translate }}</label>
              <input (ngModelChange)="patientLastNameSearch.next($event)" [(ngModel)]="filters.last_name"
                    [placeholder]="('form.labels.patient_last_name' | translate)"
                    class="form-control form-control-sm"
                    id="patientLastNameSearch"
                    required type="text">
            </div>

            <!-- <div class="divider"></div> -->

            <div class="form-group">
              <app-select
                id="select-care-module"
                [selectClass]="'ng-select-sm'"
                [placeholder]="('pages.default.administration.patients.pathway' | translate)"
                [clearable]="false"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="false"
                [items]="careModules"

                [(ngModel)]="filters.care_module_uid"
                [bindValue]="'value'"
                (ngModelChange)="onCareModuleFilterChange()"

                [asFilter]="true"
                [showCheckboxes]="false"
              ></app-select>
            </div>

            <div class="form-group">

              <div class="form-group">
                <!-- TODO:Translation: new label: form.labels.status -->
                <app-select
                  id="dropdown-btn-assignments"
                  [placeholder]="('form.labels.status' | translate)"
                  [selectClass]="'ng-select-sm'"
                  [clearable]="false"
                  [searchable]="false"
                  [hideSelected]="false"
                  [multiple]="false"
                  [items]="assignmentOptionsList"
                  [bindValue]="'value'"
                  [(ngModel)]="assignmentOption"

                  (ngModelChange)="onChangeAssignment()"

                  [asFilter]="false"
                ></app-select>
              </div>
            </div>

            <div *ngIf="filtersAreSet" class="divider"></div>

            <button (click)="onActionResetFilters()" *ngIf="filtersAreSet" class="btn btn-link"
                    type="button" id="admin-patients-reset-filters">{{ 'action.reset' | translate }}</button>
          </div>

        </div>

        <div class="col col-auto">

          <div class="filters-group">
            <div class="form-group">
              <app-select
                id="dropdown-btn-sort"
                [label]="'general.sort_by' | translate"
                [selectClass]="'ng-select-sm'"
                [clearable]="false"
                [searchable]="false"
                [hideSelected]="false"
                [multiple]="false"
                [items]="sortingOptions"
                [bindValue]="'value'"
                [(ngModel)]="sorting"

                (ngModelChange)="onChangeSort()"

                [asFilter]="false"
              ></app-select>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="isLoading" class="container">
      <app-page-loader></app-page-loader>
    </div>

    <div *ngIf="!isLoading" class="container">
      <div class="empty-state my-5 my-lg-7" *ngIf="allPatients && allPatients.length <= 0">
        <span [inlineSVG]="'/assets/svg-color/no-notifications-graphic.svg'"></span>
        <h6>{{ 'pages.default.dashboard.no_patients_yet' | translate }}</h6>
      </div>

      <div *ngIf="allPatients && allPatients.length > 0">
        <div class="block-checkbox pl-2 pb-1">
          <div class="form-check">
            <input (change)="onToggleAllCheckbox()" class="form-check-input"
                    id="checkbox_all"
                    [checked]="selectedAll"
                    type="checkbox">
            <label class="form-check-label text-muted" for="checkbox_all">{{ 'general.select_all' | translate }}</label>
          </div>
        </div>
      </div>
      <div *ngFor="let patient of allPatients; let index = index;">
        <div id="patient-{{index}}" [ngClass]="{'block-selected': selectedPatientsMap.get(patient)}" class="single-patient-block mb-2 e2e-patient-block">

          <div class="block-header">

            <div class="form-check">
              <input (change)="onToggleCheckbox(patient)" class="form-check-input"
                    id="checkbox_item_A{{index}}"
                    type="checkbox" [checked]="selectedPatientsMap.get(patient)">
              <label class="form-check-label" for="checkbox_item_A{{index}}"></label>
            </div>

            <div class="patient-name" (click)="onToggleCheckbox(patient)">
              {{ patient.first_name}} {{ patient.last_name }}
            </div>

            <div class="patient-date">
              {{ patient.date_of_birth | date:dateFormat?.format  }}
            </div>

            <div class="patient-actions">
              <div class="dropdown" dropdown placement="top right" *ngIf="patient.resend_onboarding_email_allowed">
                <button [id]="'button-admin-patients-more-actions-' + patient.uid" dropdownToggle type="button" class="block-action btn btn-tertiary btn-icon"
                        aria-controls="dropdown-task-more-actions">
                  <span [inlineSVG]="'/assets/svg/more-24.svg'"></span>
                </button>
                <ul [id]="'dropdown-admin-patients-more-actions-' + patient.uid" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" [attr.aria-labelledby]="'button-admin-patients-more-actions-' + patient.uid">
                  <li role="menuitem">
                    <a class="dropdown-item" href="" (click)="resendOnboardingEmail($event, patient)">{{ 'pages.default.administration.patients.resend_onboarding_email' | translate }}</a>
                  </li>
                </ul>
              </div>
            </div>

          </div>

          <div class="block-content">
            <div class="row">

              <div class="col-4 start-content">
                <p class="font-weight-semibold mb-1">
                  {{ getCareModuleForPatient(patient)?.care_module?.name | translateHelp | translate }}
                </p>

                <ul class="small">
                  <li *ngIf="patient.created_at" class="e2e-added-on">
                    {{ 'pages.default.administration.patients.added_on' | translate }}&nbsp;{{ patient.created_at | date:dateFormat?.format }}
                  </li>
                  <li *ngIf="!patient.consented_at && patient.status !== 'ACTIVE'" class="e2e-not-consented">
                    {{ 'pages.default.administration.patients.not_consented' | translate }}
                  </li>
                  <li *ngIf="patient.consented_at" class="e2e-consented">
                    {{ 'pages.default.administration.patients.consented_on' | translate }} {{patient.consented_at | date:dateFormat?.format }}
                  </li>
                  <li *ngIf="!patient.consented_at && patient.status === 'ACTIVE'" class="e2e-consented-and-active">
                    {{ 'pages.default.administration.patients.no_consent_required' | translate }} {{patient.consented_at | date:dateFormat?.format }}
                  </li>
                </ul>
              </div>

              <div class="col-8 end-content">
                <div class="row">
                  <div class="col-6">
                    <label class="extra-small text-muted">
                      {{ 'components.patient_tabbed_details.clinical_lead' | translate }}
                    </label>
                    <p>
                      {{ getCareModuleForPatient(patient)?.clinical_lead?.getFullName() || '...' }}
                    </p>
                  </div>
                  <div class="col-6">
                    <label class="extra-small text-muted">
                      {{ 'components.patient_tabbed_details.case_manager' | translate }}
                    </label>
                    <p>
                      {{ getCareModuleForPatient(patient)?.case_manager?.getFullName() || '...' }}
                    </p>
                  </div>
                  <div class="col-12">
                    <label class="extra-small text-muted">
                      <!--    TODO: Add translations-->
                      <!-- TODO:Translation: new label: form.labels.mdt -->
                      {{ 'form.labels.mdt' | translate }}
                    </label>
                    <p class="e2e-no-mdt-assigned" *ngIf="!hasAssignedMdt(patient)">
                      {{ 'pages.default.administration.patients.no_mdt_assigned' | translate }}
                    </p>
                    <p class="e2e-mdt-assigned" *ngIf="hasAssignedMdt(patient)">
                      {{ 'pages.default.administration.patients.mdt_assigned' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="layout-footer-pusher footer-paging"></div>
    <div class="layout-footer footer-paging">
      <div class="footer-content">
        <div class="container align-items-center">
          <!-- total items -->
          <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
          <!-- pagination -->
          <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
          <!-- pagination range -->
          <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
