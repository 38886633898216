<div class="faq-block">
  <div class="heading">
    <img [src]="category.icon">
    <h3>{{ category?.title }}</h3>
  </div>

  <div class="items">
    <button (click)="onGoToFaq(item, category, section)" *ngFor="let item of category.items"
      class="btn text-start px-0 d-flex align-items-center">
      <span>{{ item.question }}</span>
      <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big-old.svg'"></span>
    </button>
  </div>
</div>
