<div class="layout-header-pusher header-back-filters"></div>

<div class="layout-header header-back-filters">
  <div class="header-content">
    <div class="container">
      <app-back-link route="/analytics" label="pages.default.analytics.back-to-analytics" id="analytics-detail-back" *ngIf="view === 'careModule'" />

      <a href="" (click)="selectModule($event)" class="fw-bold text-decoration-none" id="analytics-detail-back-to-overview" *ngIf="view === 'phase'">
        <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
        <span class="align-middle">{{ 'pages.default.analytics.back-to-pathway-overview' | translate }}</span>
      </a>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.analytics.pathways-overview.title' | translate }}</h1>
    </div>

    <div class="container" *ngIf="view === 'careModule'">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <div class="filters-group">
            <app-select
              id="select-care-module"
              class="d-inline-block"
              [placeholder]="'pages.default.analytics.pathways-overview.care_module' | translate "
              [clearable]="false"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="careModules"
              [(ngModel)]="selectedCareModuleUid"
              [bindValue]="'value'"
              (ngModelChange)="onCareModuleChange()"

              [asFilter]="true"
              [showCheckboxes]="true"
            ></app-select>
          </div>
        </div>
      </div>
    </div>

    <div class="container" *ngIf="view === 'phase'">
      <div class="breadcrumb">
        <a href="" (click)="selectModule($event)" class="crumb-link">{{ selectedCareModule.translationKey | translate }}</a>
        <span class="rtl-mirrored-inline-svg crumb-delimiter" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>

        <ng-container *ngFor="let phase of breadcrumb" id="breadcrumb">
          <a href="" *ngIf="!currentPhase(phase)"  (click)="selectPhase(phase, $event)" class="crumb-link">{{ phase.title }}</a>
          <span *ngIf="!currentPhase(phase)" class="rtl-mirrored-inline-svg crumb-delimiter" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
          <span *ngIf="currentPhase(phase)" class="crumb-label">{{ phase.title }}</span>
        </ng-container>

      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" >
    <app-dna-kpis [careModuleUid]="selectedCareModuleUid" [phase]="phase"></app-dna-kpis>
    <hr/>

    <div id="zone_patients_in_progress" class="">
      <h2>{{ 'pages.default.analytics.pathways-overview.patients-in-progress' | translate}}</h2>
      <app-dna-phases [careModuleUid]="selectedCareModuleUid" [phase]="phase" (phaseSelected)="selectPhase($event)"></app-dna-phases>
    </div>

    <div id="zone_patients_list" *ngIf="view === 'phase'">
      <div class="py-3"></div>
      <app-dna-patients-table [careModuleUid]="selectedCareModuleUid" [phase]="phase"></app-dna-patients-table>
    </div>
  </div>
</div>
