<div class="modal-header">
  <h2 class="mb-0">{{ 'modals.edit_patient.title' | translate }}</h2>

  <button type="button" class="close" id="edit-patient-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
</div>

<div class="modal-body">
  <div class="is-loading" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <form *ngIf="!isLoading" [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
    <!-- Profile picture -->
    <label class="small">{{ 'form.labels.profile_picture' | translate }}</label>

    <div class="control-profile-picture mb-3">
      <app-avatar-input formControlName="profile_picture"></app-avatar-input>
    </div>

    <!-- Gender -->
    <div class="form-group pb-2">
      <fieldset>
      <legend class="small d-block required">{{ 'form.labels.gender' | translate }}</legend>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="radioSexMale" formControlName="gender" value="MALE">
        <label class="custom-control-label" for="radioSexMale">{{ 'form.labels.male' | translate }}</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="radioSexFemale" formControlName="gender" value="FEMALE">
        <label class="custom-control-label" for="radioSexFemale">{{ 'form.labels.female' | translate }}</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="radioSexOther" formControlName="gender" value="UNKNOWN">
        <label class="custom-control-label" for="radioSexOther">{{ 'form.labels.other' | translate }}</label>
      </div>
      </fieldset>
      <app-feedback-field [field]="form.get('gender')" [extraClass]="'general-feedback'"></app-feedback-field>
    </div>

    <!-- First name -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputFirstName" class="form-control w-100"
        [placeholder]="('form.labels.first_name' | translate)" formControlName="first_name">
      <label for="inputFirstName" class="required">{{ 'form.labels.first_name' | translate }}</label>
      <app-feedback-field [field]="form.get('first_name')"></app-feedback-field>
    </div>

    <!-- Last name -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputLastName" class="form-control w-100"
        [placeholder]="'.form.labels.last_name' | translate" formControlName="last_name">
      <label for="inputLastName" class="required">{{ 'form.labels.last_name' | translate }}</label>
      <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
    </div>

    <!-- Preferred name -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputPreferredName" class="form-control w-100"
        [placeholder]="'.form.labels.preferred_name' | translate" formControlName="preferred_name">
      <label for="inputPreferredName">{{ 'form.labels.preferred_name' | translate }}</label>
      <app-feedback-field [field]="form.get('preferred_name')"></app-feedback-field>
    </div>

    <!-- Country -->
    <div class="form-group">
      <app-select [placeholder]="('form.labels.country' | translate)" [clearable]="false" [searchable]="true"
        [hideSelected]="false" [multiple]="false" [items]="countryOptions" [bindValue]="'value'"
        [autoSortOnLabel]="true" formControlName="country" [asFilter]="false" [showCheckboxes]="false"></app-select>
      <app-feedback-field [field]="form.get('country')"></app-feedback-field>
    </div>

    <!-- Language -->
    <div class="form-group required">
      <app-select [placeholder]="('form.labels.language' | translate)" [clearable]="false" [searchable]="true"
        [hideSelected]="false" [multiple]="false" [items]="languageOptions" [bindValue]="'value'"
        [autoSortOnLabel]="true" [selectedItemsOnTop]="false" formControlName="language" [asFilter]="false"
        [showCheckboxes]="false"></app-select>
      <app-feedback-field [field]="form.get('language')"></app-feedback-field>
    </div>

    <!-- Date of birth -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputBirth" class="form-control w-100"
        [placeholder]="('form.labels.date_of_birth' | translate)" bsDatepicker formControlName="date_of_birth">
      <label for="inputBirth">{{ 'form.labels.date_of_birth' | translate }}</label>
    </div>

    <hr>

    <!-- EMR -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputEMR" class="form-control w-100"
        [placeholder]="'modals.add_patient_wizard.emr' | translate" formControlName="electronic_medical_record"
        [maxlength]="emrMaxLength">
      <label for="inputEMR">{{ 'modals.add_patient_wizard.emr' | translate }}</label>
      <p class="small text-muted text-align-end m-0"
        *ngIf="!(validationVisible && !form?.controls?.electronic_medical_record?.value)">
        {{( (form?.controls?.electronic_medical_record?.value?.length || '0') | translateNumber )}}/{{emrMaxLength |
        translateNumber}}
      </p>
      <app-feedback-field [field]="form.get('electronic_medical_record')"></app-feedback-field>
    </div>

    <hr>

    <!-- Email adress (if patient is not fully onboarded yet) -->
    <div class="row">
      <div class="col">
        <div class="form-group styled floating-label mb-0">
          <input type="email" id="inputEmail" class="form-control w-100"
            [placeholder]="('form.labels.email' | translate)" formControlName="email">
          <label for="inputEmail">{{ 'form.labels.email' | translate }}</label>
        </div>
      </div>
      <div class="col-auto" *ngIf="patient?.email_change_allowed">
        <button class="btn btn-link h-100" (click)="showEditEmailModal()" id="edit-patient-email">{{
          'modals.edit_patient.edit_email' | translate}}</button>
      </div>
    </div>
  </form>
</div>


<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-patient-modal-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate
      }}</button>

    <button class="btn btn-secondary m-start-3" id="edit-patient-modal-confirm" [ngClass]="{'loader': isSaving}"
      (click)="handleConfirm()">

      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
