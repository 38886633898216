<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/settings']" class="text-secondary font-weight-bold" id="settings-personal-data-back">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_settings' | translate }}</span>
        </a>
      </p>
      <h1>{{ 'pages.default.settings.personal_data.title' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <div class="single-item-block mb-2">
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.personal_data.export_personal_data' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.personal_data.export_personal_data_text' | translate }}</p>
      </div>
      <a href="" (click)="showExportPersonalDataModal($event)" class="block-action m-start-auto" id="settings-personal-data-export-personal-data-modal">
        <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big-old.svg'"></span>
        <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </a>
    </div>

    <div class="single-item-block mb-2">
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.personal_data.restrict_processing_personal_data' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.personal_data.restrict_processing_personal_data_text' | translate }}</p>
      </div>
      <a href="" (click)="showRestrictProcessingDataModal($event)" class="block-action m-start-auto" id="settings-personal-data-restrict-processing-data-modal">
        <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big-old.svg'"></span>
        <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </a>
    </div>

    <div class="single-item-block mb-2">
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.personal_data.delete_acc_personal_data' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.personal_data.delete_acc_personal_data_text' | translate }}</p>
      </div>
      <a href="" (click)="showDeleteAccountModal($event)" class="block-action m-start-auto" id="settings-personal-data-delete-account-modal">
        <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big-old.svg'"></span>
        <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </a>
    </div>
  </div>
</div>
