import { Component, OnInit } from '@angular/core';
import { environment } from './../environments/environment';
import { NavigationEnd, Router, ActivatedRoute, Event, RouterEvent } from '@angular/router';
import { HomeFlowService } from './services/home-flow.service';
import { AuthenticationService } from './services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from './services/general.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './services/api.service';
import { ErrorService } from './services/error.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LocaleService } from './services/locale.service';
import { LanguageService } from './services/language.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    '(document:keyup)': 'onDocumentKeyUp($event)'
  }
})
export class AppComponent implements OnInit {
  public routerEventSubscription: Subscription;

  public onlyWebPortalEnabled: boolean;
  public onlyMobileAppEnabled: boolean;

  public isIeOrEdge: boolean;
  public environmentVars = environment;

  public appStore: { icon: string, url: string };
  public playStore: { icon: string, url: string };
  public showAppStoreIcons = false;


  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public homeFlowService: HomeFlowService,
    public authenticationService: AuthenticationService,
    public modalService: BsModalService,
    public toastrService: ToastrService,
    public generalService: GeneralService,
    public errorService: ErrorService,
    public translate: TranslateService,
    public apiService: ApiService,
    public localeServide: LocaleService,
    public languageService: LanguageService
  ) {

  }

  ngOnInit(): void {
    // listn to support changes
    this.homeFlowService.onSupportChanged.subscribe(support => this.onSupportChanged(support));

    // listn to log out event
    this.authenticationService.onLogOut.subscribe(() => this.doLogOutRules());

    this.router.events.subscribe(event => this.onRouterEvent(event));

    // this.evaluateUserState();
    // this.evaluateDevice();
    this.localeServide.doLocaleConfiguration();
    this.showAppStoreIcons = true;
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.onDocumentKeyUp);
  }

  onRouterEvent(event: Event|RouterEvent) {
    if (event instanceof NavigationEnd) {
      window.scrollTo(0, 0);
    }
  }

  onDocumentKeyUp(event) {
    if (environment.production) {
      return false;
    }

    if (event.code === 'KeyR' && event.ctrlKey) {
      if (document.documentElement.dir !== 'rtl') {
        document.documentElement.dir = 'rtl';
        this.languageService.loadStyle('rtl');
      } else {
        document.documentElement.dir = '';
        this.languageService.loadStyle('ltr');
      }
    }

    if (event.code === 'KeyI' && event.ctrlKey) {
      this.isIeOrEdge = !this.isIeOrEdge;
    }

    if (event.code === 'KeyL' && event.ctrlKey) {
      this.authenticationService.logout();
    }
  }

  detectIeOrEdge(): void {
    this.isIeOrEdge = /msie\s|trident\/|edge\/|edg\//i.test(window.navigator.userAgent);
  }

  evaluateUserState() {
    this.routerEventSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.onEvaluateUserStateEvent(event);
        }
      });
  }

  onEvaluateUserStateEvent(event: NavigationEnd) {
    if (event.url === '/' || this.homeFlowService.isFlowUrl()) {
      this.authenticationService.destroyAuth();
    } else if (!event.url.includes('/cookie-policy') && !event.url.includes('/inactive') && !event.url.includes('/mobile-redirect')) {
      this.evaluateRefresh();
    }

    // should only check once, after a NavigationEnd has been triggered
    this.routerEventSubscription.unsubscribe();
    sessionStorage.setItem('reloaded', 'yes');
  }

  evaluateRefresh() {
    if (environment.refreshEvaluation) {
      if (sessionStorage.getItem('reloaded') != null) {
        this.router.navigateByUrl('/dashboard');
      } else {
        this.doSecurityLogout();
      }
    }
  }

  doSecurityLogout(): void {
    const authentication = this.authenticationService.authentication;
    if (authentication) {
      this.authenticationService.logout();
      this.router.navigateByUrl('/');

      setTimeout(() => {
        this.showAutoLogoutToast();
      }, 500);
    }
  }

  showAutoLogoutToast() {
    this.toastrService.info(this.translate.instant('app_component.auto_logout'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  onSupportChanged(support): void {
    if (support === HomeFlowService.SupportPortal) {
      this.onlyWebPortalEnabled = true;
    } else if (support === HomeFlowService.SupportApp) {
      this.onlyMobileAppEnabled = true;
    }
  }

  doLogOutRules() {
    // Navigate to root
    this.router.navigateByUrl('/');

    // Close all modals
    const openModals = document.querySelectorAll('.modal.show') || [];

    openModals.forEach((modal) => {
      const closeBtn: HTMLElement = modal.querySelector('[data-dismiss="modal"]');

      if (closeBtn) closeBtn.click();
    });
  }

  onActivate(): void {
    window.scroll(0, 0);
  }

  evaluateDevice() {
    this.detectIeOrEdge();

    if (/Android/i.test(navigator.userAgent)) {
      this.storeSetup('android');
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      this.storeSetup('ios');
    } else {
      this.storeSetup('android');
      this.storeSetup('ios');
    }
  }

  storeSetup(store: 'android' | 'ios'): void {
    this.homeFlowService.getStoreIconUrl(store).subscribe(iconUrl => {
      if (store === 'android') {
        this.playStore = {
          icon: iconUrl,
          url: environment.playStore
        }
      }

      if (store === 'ios') {
        this.appStore = {
          icon: iconUrl,
          url: environment.appStore
        }
      }
    })
  }
}
