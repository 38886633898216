<div class="modal-header">
  <h6 class="h7">{{ title }}</h6>
  <button type="button" class="btn-close" id="confirm-modal-close" data-dismiss="modal" aria-label="Close" (click)="handleClose()"></button>
</div>
<div class="modal-body">
  <p class="m-0">{{ description }}</p>
</div>
<div class="modal-footer">
  <div class="ms-auto">
    <button class="btn btn-secondary" id="confirm-modal-no" (click)="handleNo()">{{ no || ('action.cancel' | translate ) }}</button>
    <button class="btn m-start-2" id="confirm-modal-yes" (click)="handleYes()" [ngClass]="{'loader': showYesLoading, 'btn-primary': !isDanger, 'btn-danger': isDanger }">
      <span class="loader" *ngIf="showYesLoading" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ yes || ('action.yes' | translate ) }}</span>
    </button>
  </div>
</div>
