<div class="help-center-modal">
  <div class="modal-header">
    <div class="logo icon text-jnj" [inlineSVG]="'/assets/svg/logo-polyphonic.svg'"></div>

    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>
  </div>

  <div class="modal-body">
    <div [ngSwitch]="currentView">
      <div *ngSwitchCase="helpCenterModalViews.OVERVIEW">
        <div class="overview">
          <div *ngFor="let helpcenterSection of helpcenterSections; last as last">
            <!-- Faqs -->
            <div *ngIf="helpcenterSection.isFaqs">
              <app-help-center-faq-list [helpcenterSection]="helpcenterSection" [compact]="true"
                (goToOverview)="goToSectionOverview($event)" (goToFaq)="goToFaq($event)"></app-help-center-faq-list>
            </div>
            <!-- /Faqs -->

            <!-- Contact -->
            <div *ngIf="helpcenterSection.isContact">
              <app-help-center-contact [helpcenterSection]="helpcenterSection"
                [compact]="true"></app-help-center-contact>
            </div>
            <!-- /Contact -->
            <hr *ngIf="!last" />
          </div>
        </div>
      </div>


      <div *ngSwitchCase="helpCenterModalViews.FAQ_OVERVIEW">
        <div class="faq-overview">
          <button class="btn btn-link d-flex align-items-center p-start-0 mb-3" (click)="goToOverview()">
            <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
            <span>{{ 'modals.help_center.return_to_overview' | translate }}</span>
          </button>

          <div class="faq-block" *ngFor="let category of faqs">
            <app-faq-block [category]="category" (goToFaq)="goToFaq($event)"></app-faq-block>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="helpCenterModalViews.FAQ_DETAIL">
        <div class="faq-detail">
          <button class="btn btn-link d-flex align-items-center p-start-0 mb-3" (click)="goToOverview()">
            <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
            <span>{{ 'modals.help_center.return_to_overview' | translate }}</span>
          </button>

          <app-faq-content [faq]="faqSelected" [category]="categorySelected"
            (goToFaq)="goToFaq($event)"></app-faq-content>
        </div>
      </div>
    </div>


  </div>
</div>
