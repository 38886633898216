<div class="layout-header-pusher header-single-link"></div>

<div class="layout-header header-single-link">
  <div class="header-content">
    <div class="container">
      <app-back-link [route]="backButtonData?.url" label="pages.helpcenter.faq_detail.back_to_faq" />
    </div>
  </div>
</div>

<div class="body-content">

  <div class="container">
    <div class="article-content">

      <!-- content-head - start -->
      <!-- (should always be here, is not in the repeater loop) -->
      <div class="content-head">
        <h1 class="m-0">{{ faq?.question }}</h1>
      </div>
      <!-- content-head - end -->
      <ng-container *ngFor="let answer of faq?.answer">
        <div class="content-partial" *ngIf="answer['text']" [innerHtml]="answer.text.text"></div>
        <div class="content-image" *ngIf="answer['image']">
          <img [src]="answer.image?.file?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp'" alt="">
        </div>
      </ng-container>

      <ng-container *ngIf="category?.items?.length > 1">
        <div class="content-custom mt-7 mt-lg-8">
          <div class="single-item-block block-muted mb-2">
            <div class="block-wrapper">
              <p class="label">{{ 'pages.helpcenter.faq_detail.other_in' | translate }} {{ category?.title }}</p>
            </div>
          </div>

          <div class="faq-block">
            <div class="items">
              <ng-container *ngFor="let item of category?.items">
                <a href="" (click)="goToFaq($event,item, category)" *ngIf="item.uid != faq.uid" class="d-flex align-items-center">
                  <span>{{ item.question }}</span>
                  <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big-old.svg'"></span>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>


  </div>
</div>
