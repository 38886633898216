<p class="fw-semibold">{{ 'modals.add_patient_wizard.fill_patient_details' | translate }}</p>

<form [formGroup]="patientForm" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
  <!-- Email -->
  <div class="form-group required">
    <label for="inputEmail" class="required">
      {{ 'modals.add_patient_wizard.email' | translate }}
    </label>

    <input type="email" id="inputEmail" class="form-control w-100"
           [placeholder]="" formControlName="email" autocomplete="off">

    <app-feedback-field [field]="patientForm.get('email')"></app-feedback-field>
  </div>

  <div class="row form-group">
    <div class="col-6">
      <!-- First name -->
      <div class="form-group">
        <label for="inputFirstName" class="required">
          {{ 'modals.add_patient_wizard.first_name' | translate }}
        </label>

        <input type="text" id="inputFirstName" class="form-control w-100" [maxlength]="nameMaxLength"
               [placeholder]="" formControlName="first_name">

        <app-feedback-field [field]="patientForm.get('first_name')"></app-feedback-field>
      </div>
    </div>
    <div class="col-6">
      <!-- Last name -->
      <div class="form-group">
        <label for="inputLastName" class="required">
          {{ 'modals.add_patient_wizard.last_name' | translate }}
        </label>

        <input type="text" id="inputLastName" class="form-control w-100" [maxlength]="nameMaxLength"
               [placeholder]="" formControlName="last_name">

        <app-feedback-field [field]="patientForm.get('last_name')"></app-feedback-field>
      </div>
    </div>
  </div>

  <!-- Date of birth -->
  <div class="form-group required">
    <label for="inputBirth" class="required">
      {{ 'modals.add_patient_wizard.date_of_birth' | translate }}
    </label>

    <input type="text" id="inputBirth" class="form-control w-100"
           [placeholder]="" bsDatepicker
           formControlName="date_of_birth">

    <app-feedback-field [field]="patientForm.get('date_of_birth')"></app-feedback-field>
  </div>

  <!-- Gender -->
  <div class="form-group form-checks-inline mb-n2 form-no-width-limit-element">
    <fieldset>
      <legend class="small d-block required" id="label_">{{ 'form.labels.gender' | translate }}</legend>

      <div class="form-check">
        <input type="radio" class="form-check-input" id="radio_details_form_sex_male" formControlName="gender" value="MALE">
        <label class="form-check-label" for="radio_details_form_sex_male">{{ 'form.labels.male' | translate }}</label>
      </div>

      <div class="form-check">
        <input type="radio" class="form-check-input" id="radio_details_form_sex_female" formControlName="gender" value="FEMALE">
        <label class="form-check-label" for="radio_details_form_sex_female">{{ 'form.labels.female' | translate }}</label>
      </div>

      <div class="form-check">
        <input type="radio" class="form-check-input" id="radio_details_form_sex_other" formControlName="gender" value="UNKNOWN">
        <label class="form-check-label" for="radio_details_form_sex_other">{{ 'form.labels.other' | translate }}</label>
      </div>
    </fieldset>
  </div>

  <!-- electronic_medical_record -->
  <div class="form-group">
    <label for="inputEMR">
      {{ 'modals.add_patient_wizard.emr' | translate }}
    </label>

    <input type="text" id="inputEMR" class="form-control w-100"
           [placeholder]="" formControlName="electronic_medical_record">

    <app-feedback-field [field]="patientForm.get('electronic_medical_record')"></app-feedback-field>
  </div>
</form>
