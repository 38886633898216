import { Component, OnInit } from '@angular/core';
import { CareModule } from '../../../models/care-module';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DateFormat } from '../../../models/date-format';
import { LocaleService } from '../../../services/locale.service';
import { HospitalService } from '../../../services/hospital.service';
import { HealthCareProfessional } from '../../../models/health-care-professional';
import { GeneralService } from '../../../services/general.service';
import { PatientService } from '../../../services/patient.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { HcpService } from '../../../services/hcp.service';
import { Patient } from '../../../models/patient';
import { DataService } from '../../../services/data.service';
import { TranslateNumberPipe } from '../../../pipes/translate-number.pipe';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../services/language.service';
import { AddPatientWizardModalComponent } from '../../../modals/add-patient-wizard-modal/add-patient-wizard-modal.component';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  public isLoading: boolean;
  public isLoadingHCPs: boolean;


  public careModules: Array<CareModule>;
  public hcps: Array<HealthCareProfessional>;
  public patients: Array<Patient>;

  public dateFormat: DateFormat;

  public filters: any;
  public filtersAreSet: boolean;
  public patientSorting = 'last_name,asc;first_name,asc';
  public patientSortingOptions = [
    { value: 'care_module_id,asc', translationKey: 'pages.default.dashboard.pathway' },
    { value: 'last_name,asc;first_name,asc', translationKey: 'pages.default.dashboard.name_az'  },
    { value: 'last_name,desc;first_name,desc', translationKey: 'pages.default.dashboard.name_za'  },
    { value: 'created_at,asc', translationKey: 'pages.default.dashboard.date_added_asc'  },
    { value: 'created_at,desc', translationKey: 'pages.default.dashboard.date_added_desc'  },
  ];
  public hcpSorting = 'last_name,asc';
  public hcpSearchTerm: string;
  public patientLastNameSearch = new Subject<string>();
  public hcpLastNameSearch = new Subject<string>();

  public currentPage = 1;
  public totalPages: number;
  public totalElements: number;
  public currentPageSize = 10;

  constructor(
    public localeService: LocaleService,
    public hospitalService: HospitalService,
    public hcpService: HcpService,
    public patientService: PatientService,
    public modalService: ModalService,
    public authenticationService: AuthenticationService,
    public dataService: DataService,
    public translateNumber: TranslateNumberPipe,
    public translateService: TranslateService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.dataService.clear('selectedPathway');
    this.clearFilters();
    this.getCareModules();
    this.getPatients().subscribe(() => { });

    this.patientLastNameSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.currentPage = 1;
        this.filters.last_name = value;

        if (value && value.length){
          this.filtersAreSet = true;
        }

        this.getPatients().subscribe(() => { });
      });

    this.hcpLastNameSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.getHCPs(value);
      });
  }

  getPatients(showLoading = true) {
    if (showLoading) {
      this.isLoading = true;
      this.patients = undefined;
      this.totalPages = undefined;
      this.totalElements = undefined;
    }

    return new Observable(observer => {
      let observable;

      if (this.authenticationService.hasCcRole()) {
        const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
        observable = this.patientService.getDashboardPatientsByHospital(
          hospitalUid, this.filters, this.patientSorting, this.currentPage - 1, this.currentPageSize);
      } else {
        const hcpUid = this.hcpService.getCurrentStoredHcpUid();
        observable = this.patientService.getDashboardPatientsByHcp(
          hcpUid, this.filters, this.patientSorting, this.currentPage - 1, this.currentPageSize);
      }

      observable.subscribe(result => {
        this.isLoading = false;
        this.patients = result.patients;

        if (result.pagination) {
          this.totalPages = result.pagination.total_pages;
          this.totalElements = result.pagination.total_elements;
        }

        observer.next();
        observer.complete();
      }, () => {
        this.isLoading = false;
        observer.next();
        observer.complete();
      });
    });
  }

  clearFilters() {
    this.filtersAreSet = false;

    this.patientSorting = 'last_name,asc;first_name,asc';

    this.filters = {
      last_name: '',
      care_module_uids: [],
      patient_mdt_hcp_uid: null
    };
  }

  actionResetFilters() {
    this.clearFilters();
    this.getPatients().subscribe(() => { });
  }

  getHCPs(event?) {
    let term = '';

    if (event && event.term && event.term.length) {
      term = event.term;
      this.hcpSearchTerm = term;
    } else {
      this.hcps = undefined;
    }

    this.isLoadingHCPs = true;

    this.hcpService.getPaged({ last_name: term }, this.hcpSorting, 0, 50).subscribe({
      next: result => {
        this.hcps = result.items;
        this.isLoadingHCPs = false;
      }, error: () => this.isLoadingHCPs = false
    });
  }

  getCareModules() {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.hospitalService.getCareModules(hospitalUid).subscribe(careModules => {
      this.careModules = careModules;
    });
  }

  canCreatePatient() {
    return this.authenticationService.hasCcRole();
  }

  openAddPatientModal() {
    const modalRef = this.modalService.showWithInterceptor(
      AddPatientWizardModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
      }),
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: this.translateService.instant('modals.confirm_add_patient_wizard.title'),
          description: this.translateService.instant('modals.confirm_add_patient_wizard.description'),
          yes: this.translateService.instant('modals.confirm_add_patient_wizard.confirm'),
          no: this.translateService.instant('modals.confirm_add_patient_wizard.cancel')
        }
      })
    );

    modalRef.content.onPatientCreated.subscribe(() => {
      this.getPatients(false).subscribe(() => { });
    });
  }

  customSearchFn() {
    return true; // always return, searching is done at the backend
  }

  onFilterChange() {
    this.filtersAreSet = true;
    this.currentPage = 1;
    this.getPatients().subscribe(() => { });
  }

  onChangeSortDirection() {
    this.getPatients().subscribe(() => { });
  }

  onPaginationPageChanged(page) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getPatients().subscribe(() => { });
    }
  }

  onPaginationRangeChanged(range) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getPatients().subscribe(() => { });
    }
  }

  getLabelForTotalElements(): string {
    const translatedNumber = this.translateNumber.transform(this.totalElements);
    const itemsLabel = (this.totalElements > 1) ?
      this.translateService.instant('components.pagination.items_total') :
      this.translateService.instant('components.pagination.items_total_singular');

    if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1) {
      return itemsLabel + ' ' + translatedNumber;
    }

    return translatedNumber + ' ' + itemsLabel;
  }
}
