<div class="is-loading row" *ngIf="isLoading">
  <app-page-loader class="col-12 align-self-center"></app-page-loader>
</div>

<div *ngIf="!isLoading">
  <p class="fw-semibold">{{ 'modals.add_patient_wizard.fill_region_details' | translate }}</p>

  <form [formGroup]="regionForm" autocomplete="off">
    <!-- Country -->
    <div class="form-group required">
<!--      TODO add translation for Select-->
      <app-select
        [label]="('form.labels.country' | translate)"
        [placeholder]="'Select'"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="countryOptions"
        [autoSortOnLabel]="true"
        [asFilter]="false"
        [showCheckboxes]="false"
        formControlName="country"
      >
      </app-select>

      <app-feedback-field [field]="regionForm.get('country')"></app-feedback-field>
    </div>

    <!-- Language -->
    <div class="form-group required">
      <!--      TODO add translation for Select-->
      <app-select
        [label]="('form.labels.language' | translate)"
        [placeholder]="'Select'"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="languageOptions"
        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"
        formControlName="language"
        [asFilter]="false"
        [showCheckboxes]="false"
      >
      </app-select>

      <app-feedback-field [field]="regionForm.get('language')"></app-feedback-field>
    </div>
  </form>
</div>
