

  <div class="info-box info-box-warning sidebar-open-element-visible mx-2 mb-4 mt-n4" *ngIf="banner">

    <!-- TODO - Correct icons -->
    <em *ngIf="banner?.type === bannerType.MAINTENANCE"   class="info-box-icon" [inlineSVG]="'/assets/svg/wrench.svg'"></em>
    <em *ngIf="banner?.type === bannerType.CRITICAL"      class="info-box-icon" [inlineSVG]="'/assets/svg/caution.svg'"></em>
    <em *ngIf="banner?.type === bannerType.WARNING"       class="info-box-icon" [inlineSVG]="'/assets/svg/notification-idle.svg'"></em>
    <em *ngIf="banner?.type === bannerType.INFORMATIONAL" class="info-box-icon" [inlineSVG]="'/assets/svg/info-circle.svg'"></em>

    <h6 class="info-box-title mb-1">{{ banner?.title }}</h6>
    <div [innerHtml]="banner?.description" class="description"></div>

    <div class="text-end">
      <button class="btn btn-tertiary" type="button" (click)="showMoreInfoModal(banner.title, banner.more_information)"
        aria-label="Read more about this notification">{{ 'action.read_more' | translate }}</button>
    </div>
  </div>

  <div class="sidebar-state-icon bg-warning sidebar-open-element-hidden" *ngIf="banner">
    <!-- <span [inlineSVG]="'/assets/svg/info-circle.svg'"></span> -->

    <em *ngIf="banner?.type === bannerType.MAINTENANCE"   [inlineSVG]="'/assets/svg/wrench.svg'"></em>
    <em *ngIf="banner?.type === bannerType.CRITICAL"      [inlineSVG]="'/assets/svg/caution.svg'"></em>
    <em *ngIf="banner?.type === bannerType.WARNING"       [inlineSVG]="'/assets/svg/notification-idle.svg'"></em>
    <em *ngIf="banner?.type === bannerType.INFORMATIONAL" [inlineSVG]="'/assets/svg/info-circle.svg'"></em>

  </div>





<!--
<div class="content-part" *ngIf="banner">
  <div class="sidebar-banner bg-danger p-2">

    <div class="p-end-2">
      <div class="d-flex">


        <h6 class="sidebar-open-element-block mb-0">{{ banner?.title }}</h6>
      </div>

      <div [innerHtml]="banner?.description" class="description sidebar-open-element-block mt-2"></div>
    </div>

    <div class="text-align-end pt-1 sidebar-open-element-block" *ngIf="hasMoreInfo">
      <button class="btn btn-light" type="button" (click)="showMoreInfoModal(banner.title, banner.more_information)"
        aria-label="Read more about this notification">{{ 'action.read_more' | translate }}</button>
    </div>
  </div>
</div> -->
