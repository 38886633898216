<div class="tabbed-details-block">
  <!-- <div class="user-info" (mouseenter)="enterUserInfo()" (mouseleave)="leaveUserInfo()" [popover]="userDetails" [isOpen]="showUserDetails" placement="right" triggers=""> -->
  <!-- JW: Changes to userdetails info flyout; this behaviour was very anoying -->
  <div class="user-info">
    <div class="personal-details" (mouseenter)="enterUserInfo()" (mouseleave)="leaveUserInfo()" [popover]="userDetails" [isOpen]="showUserDetails" placement="right" triggers="">
      <app-avatar [profilePicture]="patient?.profile_picture"></app-avatar>

      <div class="details-wrapper">
        <p class="title">{{ patient?.first_name }} {{ patient?.last_name }}</p>
        <p>{{ patient?.date_of_birth | timeZoneDate:dateFormat?.format }} ({{ patient?.age() | translateNumber }} {{
          'pages.default.dashboard.yo' | translate }})</p>
      </div>
    </div>

    <ng-template #userDetails>
      <div class="patient-info-block" (mouseenter)="enterUserDetails()" (mouseleave)="leaveUserDetails()">
        <div class="info-wrapper">
          <div class="user-compact mb-3">
            <app-avatar [profilePicture]="patient?.profile_picture"></app-avatar>

            <div class="user-compact-info">
              <p class="title text-truncate">{{ patient?.getFullName() }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <p class="text-truncate">
                <span [inlineSVG]="'/assets/svg/cake-outline-24.svg'"></span>
                <span class="text-muted">{{ patient?.date_of_birth | timeZoneDate:dateFormat?.format  }} ({{ patient?.age() | translateNumber }} {{ 'pages.default.dashboard.yo' | translate }})</span>
              </p>
            </div>

            <div class="col-6">
              <p class="text-truncate">
                <span [inlineSVG]="'/assets/svg/language-24.svg'"></span>
                <span class="text-muted">{{ getLanguage()?.translationKey | translate }}</span>
              </p>
            </div>

            <div class="col-6">
              <p class="text-truncate">
                <span [inlineSVG]="'/assets/svg/gender-24.svg'"></span>
                <span *ngIf="patient?.gender==='MALE'" class="text-muted">{{ 'form.labels.male' | translate }}</span>
                <span *ngIf="patient?.gender==='FEMALE'" class="text-muted">{{ 'form.labels.female' | translate }}</span>
                <span *ngIf="patient?.gender==='UNKNOWN'" class="text-muted">{{ 'form.labels.other' | translate }}</span>
              </p>
            </div>

            <div class="col-6">
              <p class="text-truncate">
                <span [inlineSVG]="'/assets/svg/mobile-device-outline-24.svg'"></span>
                <span *ngIf="patient?.phone_number" dir="ltr" class="text-muted">{{ generalService.getDialCodeForCountry(patient?.phone_number?.code)?.label}} {{ patient?.phone_number?.number }}</span>
                <span *ngIf="!patient?.phone_number" class="text-muted">{{ 'pages.default.patient_detail.not_available' | translate }}</span>
              </p>
            </div>

            <div class="col-6">
              <p class="text-truncate">
                <span [inlineSVG]="'/assets/svg/id-24.svg'"></span>
                <span class="text-muted">{{ (patient?.electronic_medical_record | translateNumber) || ('pages.default.patient_detail.not_available' | translate) }}</span>
              </p>
            </div>

            <div class="col-6">
              <p class="text-truncate">
                <span [inlineSVG]="'/assets/svg/mail.svg'" class="text-secondary"></span>
                <a href="mailto:{{ patient?.email }}" target="_blank" class="text-link">{{ patient?.email }}</a>
              </p>
            </div>
          </div>
        </div>

        <hr *ngIf="patient?.pathways.length" class="mt-1" />

        <div *ngIf="patient?.pathways.length">
          <p class="small text-muted mb-0">{{ 'components.patient_tabbed_details.user_details.pathways' | translate }}</p>

          <div *ngFor="let pathway of patient?.pathways; index as index">
            <a href="" class="btn btn-secondary w-100 mt-1" [routerLink]="['/patient/' + patient?.uid]" [queryParams]="{'pathwayUid': pathway?.uid }">
              <span class="text-truncate">{{ pathway.care_module?.translationKey | translate | evalTranslation }}</span>
              <span [inlineSVG]="'/assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg ms-auto"></span>
            </a>
          </div>

        </div>
      </div>
    </ng-template>
  </div>
  <div class="tabs">
    <div class="tab-list">
      <a href="" class="item active" id="pathway-tab-{{index}}"
        *ngFor="let pathway of patient?.pathways; index as index" [ngClass]="{'active' : isActivePathway(pathway), 'text-muted': isEnded(pathway) || isStopped(pathway), 'single-item': (patient?.pathways?.length == 1)}"
        (click)="setActivePathway($event, pathway)">
        <span>{{ pathway.care_module?.translationKey | translate | evalTranslation }}</span>
      </a>
    </div>

    <div class="tab-content">
      <div class="details">

        <div *ngIf="!patient?.pathways?.length" class="no-active-pathways">
          <p>{{ 'pages.default.patient_detail.no_active_pathways' | translate }}</p>
        </div>

        <div class="row" *ngIf="patient?.pathways?.length">
          <div class="col-4" *ngIf="activePathway?.clinical_lead">
            <div class="item">
              <p class="label">{{ 'components.patient_tabbed_details.clinical_lead' | translate }}</p>
              <p class="value">{{ activePathway?.clinical_lead?.getFullName() || '...' }}</p>
            </div>
          </div>

          <div class="col-4" *ngIf="activePathway?.case_manager">
            <div class="item">
              <p class="label">{{ 'components.patient_tabbed_details.case_manager' | translate }}</p>
              <p class="value">{{ activePathway?.case_manager?.getFullName() || '...' }}</p>
            </div>
          </div>

          <div class="col-4" *ngIf="isEnded(activePathway) || isStopped(activePathway)">
            <div class="item">
              <p class="label">{{ 'components.patient_tabbed_details.status' | translate }}</p>
              <p class="value">
                <span class="badge badge-light">{{ 'components.patient_tabbed_details.status.' + activePathway?.status.toLowerCase() | translate }}</span>
              </p>
            </div>
          </div>

          <div class="col-4" *ngIf="activePathway?.ended_at && isEnded(activePathway)">
            <div class="item">
              <p class="label">{{ 'components.patient_tabbed_details.ended_at' | translate }}</p>
              <p class="value">{{ activePathway?.ended_at | timeZoneDate }}</p>
            </div>
          </div>

          <div class="col-4" *ngIf="activePathway?.ended_at && isStopped(activePathway)">
            <div class="item">
              <p class="label">{{ 'components.patient_tabbed_details.stopped_at' | translate }}</p>
              <p class="value">{{ activePathway?.ended_at | timeZoneDate }}</p>
            </div>
          </div>

          <div class="col-8" *ngIf="isStopped(activePathway)">
            <div class="item">
              <p class="label">{{ 'components.patient_tabbed_details.reason' | translate }}</p>
              <p class="value">{{ 'stop_pathway_reason.' + activePathway?.stop_reason_code.toLowerCase() | translate }}</p>
            </div>
          </div>

          <div class="col-4" *ngIf="activePathway?.getCurrentPhase() && scopeService.doesCurrentPatientHasClicinalCareModule()">
            <div class="item">
              <p class="label">{{ 'components.patient_tabbed_details.current_phase' | translate }}</p>
              <p class="value text-truncate" *ngIf="activePathway?.getCurrentPhase()?.translationKey">{{ activePathway?.getCurrentPhase()?.translationKey | translate | evalTranslation }}</p>
              <p class="value" *ngIf="!activePathway?.getCurrentPhase()?.translationKey">...</p>
            </div>
          </div>

          <div class="col-12" *ngIf="activePathway?.current_educational_material_progress && isClinical(activePathway) && (!isEnded(activePathway) && !isStopped(activePathway))">
            <div class="item">
              <p class="label">{{ 'components.patient_tabbed_details.materials_read' | translate }}</p>
              <p class="value">
                {{ (activePathway?.current_educational_material_progress?.read || '0') | translateNumber }}/{{ (activePathway?.current_educational_material_progress?.total || '0') | translateNumber }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="actions">
        <div class="dropdown" dropdown placement="top right" *ngIf="isCC()">
          <button id="button-patient-more-actions" dropdownToggle type="button" class="btn btn-icon btn-tertiary more-btn"
            aria-controls="dropdown-patient-more-actions">
            <span [inlineSVG]="'/assets/svg/more-24.svg'"></span>
          </button>
          <ul id="dropdown-patient-more-actions" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-patient-more-actions">
            <li role="menuitem" *ngIf="isClinical(activePathway)">
              <a class="dropdown-item" href="" (click)="editMdt($event)">{{ 'pages.default.dashboard.edit_mdt' | translate }}</a>
            </li>

            <li role="menuitem">
              <a class="dropdown-item" href="" (click)="addPathway($event)">{{ 'pages.default.dashboard.add_pathway' |
                translate }}</a>
            </li>

            <li role="menuitem">
              <a id="patient-dashboard-stop-pathway" class="dropdown-item" href=""
                (click)="stopPathway($event)">{{'pages.default.dashboard.stop_pathway' | translate }}</a>
            </li>
          </ul>
        </div>

        <a href="" class="btn btn-secondary mt-auto action" [id]="['patient-tabbed-details-' + patient?.uid]"
          [routerLink]="['/patient/' + patient?.uid]" [queryParams]="{'pathwayUid': activePathway?.uid }">
          <!-- TODO: Add translation for 'open'-->
          <span>Open</span>
          <span [inlineSVG]="'/assets/svg/arrow-right-24.svg'" class="rtl-mirrored-inline-svg"></span>
        </a>
      </div>
    </div>
  </div>
</div>
