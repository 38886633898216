<div id="zone_reminders" class="mb-2 mb-lg-3" *ngIf="(reminders && reminders?.length) > 0  || tasks?.length > 0">
  <h5>{{ 'pages.default.patient_detail.reminders' | translate }}</h5>

  <div class="row g-2">
    <div class="col-6" *ngFor="let task of tasks | sortBy:'asc': 'order'">

      <button class="reminder-item-block item-clickable testing_task" [ngClass]="{'block-danger-OLD' : task?.passed() }" (click)="openReminder(task)">
        <div class="block-icon icon-only">
          <span class="icon" [inlineSVG]="'/assets/svg/checklist-24.svg'"></span>
        </div>

        <div class="block-wrapper m-end-auto">
          <p class="label text-truncate">{{ task?.title | translateHelp | translate | evalTranslation }}</p>
          <p class="value text-truncate" *ngIf="task?.due_date ">{{ 'pages.default.patient_detail.reminders.due_on' | translate }} {{ task?.due_date |  timeZoneDate  }}</p>
        </div>

        <div class="block-wrapper no-flex p-end-1 m-start-auto" *ngIf="task?.passed()">
          <div class="status-badge danger">
            <!--    TODO: Add translations-->
            Overdue
          </div>
        </div>

        <div class="block-btn-wrapper m-0">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span [inlineSVG]="'/assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
          </div>
        </div>
      </button>
    </div>

    <ng-container *ngFor="let reminder of reminders" >
      <div class="col-6" *ngIf="reminder?.type === 'appointment' || reminder?.type === 'message'">
        <button class="reminder-item-block item-clickable testing_{{reminder?.type}}" [ngClass]="{'block-danger-OLD' : reminder?.passed() }" (click)="openReminder(reminder)">

          <div class="block-icon icon-only">
            <span class="icon" [inlineSVG]="'/assets/svg/calendar-outline-24.svg'" *ngIf="reminder?.type === 'appointment'"></span>
            <span class="icon" [inlineSVG]="'/assets/svg/chat-outline-24.svg'" *ngIf="reminder?.type === 'message'"></span>
          </div>
          <div class="block-wrapper" *ngIf="reminder?.type === 'message'">
            <p class="label text-truncate" >{{ 'pages.default.patient_detail.reminders.unread_messages' | translate }}"{{ reminder?.messageSubject }}"</p>
          </div>

          <div class="block-wrapper m-end-auto"*ngIf="reminder?.type === 'appointment'" >
            <p class="label text-truncate">{{ 'pages.default.patient_detail.reminders.appointment_with' | translate }} {{ patient?.first_name }}</p>
            <p class="value text-truncate" *ngIf="reminder?.start_date">
              <span>{{ reminder?.start_date |  timeZoneDate  }}</span>
              <span>&nbsp;{{ 'pages.default.patient_detail.reminders.at' | translate }}&nbsp;</span>
              <span >{{ reminder?.start_date |  time }}</span>
            </p>
            <p class="value text-truncate" *ngIf="!reminder?.start_date && reminder?.date">
              <span>{{ reminder?.date |  timeZoneDate  }}</span>
            </p>
          </div>

          <div class="block-wrapper no-flex p-end-1 m-start-auto" *ngIf="reminder?.passed()">
            <div class="status-badge danger">
              <!--    TODO: Add translations-->
              Overdue
            </div>
          </div>

          <div class="block-btn-wrapper m-0">
            <div class="block-action btn btn-lg btn-tertiary btn-icon">
              <span [inlineSVG]="'/assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
            </div>
          </div>
        </button>
      </div>
    </ng-container>

  </div>

  <hr class="my-5">
</div>
