<div class="modal-header">
  <h6 class="h7">{{ 'modals.add_mfa_backup.add_mfa_backup' | translate }}</h6>
  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
    <div *ngIf="step === 'choice'">
      <div class="form-check">
        <input type="radio" class="form-check-input" id="radioUseCurrentNumber" [value]="true" [(ngModel)]="useCurrentNumber">
        <label class="form-check-label" for="radioUseCurrentNumber">
          <!-- Use {{ currentDialCode?.label }} {{ profile?.mobile_number?.number }} -->
          {{ 'modals.add_mfa_backup.use_mobile_number_value' | translate:{value: [currentDialCode?.label, profile?.mobile_number?.number].join('') } }}
        </label>
      </div>

      <div class="form-check mb-0">
        <input type="radio" class="form-check-input" id="radioUseNewNumber" [value]="false" [(ngModel)]="useCurrentNumber">
        <label class="form-check-label" for="radioUseNewNumber">
          <!-- Use new mobile number -->
          {{ 'modals.add_mfa_backup.use_other_mobile_number' | translate}}
        </label>
      </div>
    </div>

    <form [formGroup]="form">
      <div *ngIf="step === 'input'">
        <!-- TODO: Translations -->
        <p>Add a mobile phone number to use as backup method</p>

        <div class="row">
          <div class="col-5" [ngClass]="{ 'order-last': languageService.isHebrew }">
            <div class="form-group">
              <app-select
                [placeholder]="('form.labels.country_code' | translate)"
                [clearable]="false"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="false"
                [items]="dialCodeOptions"
                [bindValue]="'value'"
                [autoSortOnLabel]="true"
                [selectedItemsOnTop]="false"
                formControlName="country_code"
                [asFilter]="false"
                [showCheckboxes]="false"
              />
              <app-feedback-field [field]="form.get('country_code')"></app-feedback-field>
            </div>
          </div>
          <div class="col-7" [ngClass]="{'order-first': languageService.isHebrew}">
            <div class="form-group styled">
              <label for="inputPhone">{{ 'form.labels.phone_number' | translate }}</label>
              <input
                type="tel"
                id="inputPhone"
                class="form-control w-100"
                [placeholder]="('form.labels.phone_number' | translate)"
                formControlName="phone"
              />
              <app-feedback-field [field]="form.get('phone')"></app-feedback-field>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="step === 'code'">
        <p>{{ 'modals.add_mfa_backup.enter_code' | translate }}</p>

        <div class="row align-items-end">
          <div class="col">
            <div class="form-group styled">
              <label for="inputCode">{{ 'modals.add_mfa_backup.verification_code' | translate }}</label>
              <input
                type="text"
                id="inputCode"
                class="form-control w-100"
                [placeholder]="('modals.add_mfa_backup.verification_code' | translate)"
                formControlName="mobile_verification_code"
                required
              />
              <app-feedback-field [field]="form.get('mobile_verification_code')"></app-feedback-field>
            </div>
          </div>

          <div class="col-auto">
            <a class="btn btn-link btn-lg" href="" (click)="requestVerificationCode($event, true)">
              <span *ngIf="!isSendingCode">{{ 'modals.add_mfa_backup.resend_code' | translate }}</span>
              <em class="spinner-border spinner-border-sm" *ngIf="isSendingCode"></em>
            </a>
          </div>
        </div>
      </div>
    </form>
</div>

<div class="modal-footer">
  <div class="ms-auto">
    <button class="btn btn-secondary" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-primary m-start-2" [ngClass]="{'loader': isSaving}" (click)="handleNextStep()" *ngIf="showNextStepBtn">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.next_step' | translate }}</span>
    </button>

    <button class="btn btn-primary m-start-2" [ngClass]="{'loader': isSaving}"  (click)="handleConfirm()" *ngIf="showConfirmStepBtn">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
