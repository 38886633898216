<div class="border border-danger p-8 w-25">


  <div class="form-check">
    <input type="radio" class="form-check-input" id="a" name="flexRadioDefault">

    <label class="form-check-label" for="a">
      Label that wraps to multiple lines because it’s too long
    </label>
  </div>

  <div class="form-check">
    <input type="radio" class="form-check-input" id="b" name="flexRadioDefault">

    <label class="form-check-label" for="b">
      Label that wraps to multiple lines because it’s too long
    </label>
  </div>


</div>
