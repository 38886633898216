<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-title-only"></div>

  <div class="layout-header header-title-only">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h5>{{ 'pages.default.dashboard.patients' | translate }}</h5>
          </div>
          <div class="col col-auto">
            <button type="button" (click)="openAddPatientModal()" *ngIf="canCreatePatient()" class="btn btn-primary">{{ 'pages.default.dashboard.add_patient' | translate }}</button>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="body-content">

    <!--    TODO: DEV JASPER-->
    <!--    <app-dev-component/>-->
    <!--    TODO: DEV JASPER-->


    <!--  -->
    <!-- Filters - Start -->
    <!--  -->
    <div class="container pb-4">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">

          <div class="filters-group">

            <div class="form-group form-group-search">
              <label for="searchPatientLastName">{{ 'form.labels.patient_last_name' | translate }}</label>
              <!-- TODO:Translation - Change placeholder to 'Search' ... -->
              <input type="search" id="searchPatientLastName" class="form-control form-control-sm" placeholder="Search"
              [(ngModel)]="filters.last_name"
              (ngModelChange)="patientLastNameSearch.next($event)" required>
            </div>

            <div class="form-group">
              <!-- TODO:Translation - Change placeholder to 'Select' ... -->
              <app-select
                id="dashboard-select-clinician"
                [label]="('pages.default.dashboard.hcp' | translate)"
                [placeholder]="'Select'"
                [selectClass]="'ng-select-sm'"
                [clearable]="true"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="false"
                [items]="hcps"
                [searchFn]="customSearchFn"
                [bindValue]="'value'"
                (open)="getHCPs()"
                [loading]="isLoadingHCPs"
                [(ngModel)]="filters.patient_mdt_hcp_uid"

                (ngModelChange)="onFilterChange()"
                (search)="hcpLastNameSearch.next($event)"
                [asFilter]="true"
                [showUserDetails]="true"
                [showCheckboxes]="false"
              ></app-select>
            </div>

            <div class="form-group">
              <!-- TODO:Translation - Change placeholder to 'Select' ... -->
              <app-select
                id="dashboard-select-pathway"
                [label]="('general.pathways' | translate)"
                [placeholder]="'Select'"
                [selectClass]="'ng-select-sm'"
                [clearable]="true"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="true"
                [items]="careModules"
                [bindValue]="'value'"

                [(ngModel)]="filters.care_module_uids"

                (ngModelChange)="onFilterChange()"

                [asFilter]="true"
                [showCheckboxes]="true"
              ></app-select>
            </div>

            <button type="reset" class="btn btn-link" id="dashboard-action-reset-filters" *ngIf="filtersAreSet" (click)="actionResetFilters()">{{ 'action.reset' | translate }}</button>
          </div>

        </div>
        <div class="col col-auto">
          <div class="filters-group">
            <div class="form-group">
              <app-select
                id="dropdown-btn-sort"
                [label]="'general.sort_by' | translate"
                [selectClass]="'ng-select-sm'"
                [clearable]="false"
                [searchable]="false"
                [hideSelected]="false"
                [multiple]="false"
                [items]="patientSortingOptions"
                [bindValue]="'value'"
                [(ngModel)]="patientSorting"

                (ngModelChange)="onChangeSortDirection()"

                [asFilter]="false"
              ></app-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- Filters - End -->
    <!--  -->



    <!--  -->
    <!-- Loading - Start -->
    <!--  -->
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>
    <!--  -->
    <!-- Loading - End -->
    <!--  -->

    <div class="container" *ngIf="!isLoading">
      <div class="empty-state my-5 my-lg-7" *ngIf="patients && patients.length <= 0">
        <span [inlineSVG]="'/assets/svg-color/no-notifications-graphic.svg'"></span>
        <h6>{{ 'pages.default.dashboard.no_patients_yet' | translate }}</h6>
      </div>

      <div *ngFor="let patient of patients">
        <app-patient-tabbed-details class="mb-2" [patient]="patient"></app-patient-tabbed-details>
      </div>

    </div>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="layout-footer-pusher footer-paging"></div>
    <div class="layout-footer footer-paging">
      <div class="footer-content">
        <div class="container align-items-center">
          <!-- total items -->
          <div class="m-end-auto" id="total-patients">{{ getLabelForTotalElements() }}</div>
          <!-- pagination -->
          <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
          <!-- pagination range -->
          <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
