<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-back-filters"></div>

  <div class="layout-header header-back-filters">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <p>
              <app-back-link route="/administration" label="action.back_to_administration" id="admin-onboarding-requests-back" />
            </p>
          </div>
          <div class="col col-auto" *ngIf="selected && selected?.length">
            <a href="" class="btn btn-sm btn-muted m-start-1" id="admin-onboarding-requests-decline" (click)="bulkActionDecline($event)">{{ 'pages.default.administration.onboarding_requests.bulk_decline' | translate }}</a>
            <a href="" class="btn btn-sm btn-secondary m-start-1" id="admin-onboarding-requests-approve" (click)="bulkActionApprove($event)">{{ 'pages.default.administration.onboarding_requests.bulk_approve' | translate }}</a>
          </div>
        </div>
      </div>

      <div class="container">
        <h1>{{ 'pages.default.administration.onboarding_requests.onboarding_requests' | translate }}</h1>
      </div>

      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">

            <div class="filters-group">

              <div class="form-group styled floating-label">
                <input type="text" id="patientLastNameSearch" class="form-control w-100" [placeholder]="('form.labels?.patient_last_name' | translate)"
                [(ngModel)]="filters.last_name"
                (ngModelChange)="patientLastNameSearch.next($event)" required>
                <label for="patientLastNameSearch">{{ 'form.labels.patient_last_name' | translate }}</label>
              </div>

              <div class="divider"></div>

              <app-select
                id="admin-onboarding-requests-care-modules"
                class="d-inline-block"
                [placeholder]="('pages.default.administration.patients.pathway' | translate)"
                [clearable]="false"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="false"
                [items]="careModules"

                [(ngModel)]="filters.care_module_uid"
                [bindValue]="'value'"
                (ngModelChange)="onCareModuleFilterChange()"

                [asFilter]="true"
                [showCheckboxes]="false"
              ></app-select>

              <div class="divider"></div>

              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="checkbox_approved_requests" [(ngModel)]="showApprovedRequests" [ngModelOptions]="{standalone: true}" (change)="onShowApprovedCheck()">
                <label class="form-check-label" for="checkbox_approved_requests">{{ 'pages.default.administration.onboarding_requests.show_approved_requests' | translate }}</label>
              </div>

              <div class="divider" *ngIf="filtersAreSet"></div>

              <button type="button" class="btn btn-link text-info py-0" id="admin-onboarding-reset-filters" *ngIf="filtersAreSet" (click)="actionResetFilters()">{{ 'action.reset' | translate }}</button>
            </div>

          </div>
          <div class="col col-auto">

            <div class="btn-group text-dropdown text-dropdown-sm" dropdown>
              <button id="dropdown-btn-sort" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                <span class="m-end-1">{{ 'general.sort_by' | translate }}:</span>

                <span *ngIf="sortDirectionAsc">{{ 'pages.default.administration.onboarding_requests.date_requested' | translate }} ({{ 'general.sort_dir_asc' | translate }})&#x200E;</span>
                <span *ngIf="!sortDirectionAsc">{{ 'pages.default.administration.onboarding_requests.date_requested' | translate }} ({{ 'general.sort_dir_desc' | translate }})&#x200E;</span>

                <span class="icon" [inlineSVG]="'/assets/svg/chevron-down.svg'"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-btn-sort">
                <li role="menuitem"><a class="dropdown-item" id="admin-onboarding-sort-asc" href="" (click)="onChangeSortDirectionAsc($event, true)">{{ 'pages.default.administration.onboarding_requests.date_requested' | translate }} ({{ 'general.sort_dir_asc' | translate }})&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" id="admin-onboarding-sort-desc" href="" (click)="onChangeSortDirectionAsc($event, false)">{{ 'pages.default.administration.onboarding_requests.date_requested' | translate }} ({{ 'general.sort_dir_desc' | translate }})&#x200E; </a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="!isLoading">

      <div *ngFor="let request of requests; let index = index;">
        <div id="admin-onboarding-request-{{index}}" class="single-item-block mb-2" [ngClass]="{'block-muted': request.approved, 'block-selected': isChecked(request)}">
          <div class="block-checkbox">
            <div class="form-check" *ngIf="!request.approved">
              <input type="checkbox" class="form-check-input" id="checkbox_item_A{{index}}" (change)="checked(request)">
              <label class="form-check-label" for="checkbox_item_A{{index}}"></label>
            </div>
          </div>

          <div class="row d-flex align-items-center w-100">
            <div class="col-3">
              <div class="block-wrapper py-3">
                <p class="label text-truncate" [id]="'admin-onboarding-request-name-' + request.uid">{{ request.first_name}} {{ request.last_name }}</p>
                <p class="value text-truncate" [id]="'admin-onboarding-request-date-of-birth-' + request.uid">{{ request.date_of_birth | timeZoneDate:dateFormat.format  }}</p>
              </div>
            </div>

            <div class="col-4">
              <div class="block-wrapper">
                <p class="text-truncate text-dark" [id]="'admin-onboarding-request-pathway-' + request.uid"><strong>{{ request?.careModuleTranslationKey  | translate }}</strong></p>
                <p class="text-truncate" [id]="'admin-onboarding-request-requested-on-' + request.uid">{{ 'pages.default.administration.onboarding_requests.requested_on' | translate }} {{ request.submission_date | timeZoneDate:dateFormat.format }}</p>
              </div>
            </div>

            <div class="col-5">
              <div class="block-wrapper">
                <p class="text-truncate" [id]="'admin-onboarding-request-email-' + request.uid">
                  <a href="mailto:{{request.email}}" class="text-secondary text-semibold">
                    <span [inlineSVG]="'/assets/svg/email.svg'" class="icon small m-end-1"></span>
                    <span class="align-middle">{{request.email}}</span>
                  </a>
                </p>
                <p *ngIf="request.phone_number?.number" class="text-truncate" [id]="'admin-onboarding-request-phone-number-' + request.uid">
                  <span [inlineSVG]="'/assets/svg/mobile-device-outline-24.svg'" class="icon small rtl-mirrored-inline-svg m-end-1 align-middle"></span>
                  <span class="align-middle">{{getPhonePrefix(request.phone_number)}} {{request.phone_number?.number}}</span>
                </p>
              </div>
            </div>

          </div>

          <div class="block-wrapper text-center" style="min-width: 120px;" *ngIf="selected.length && !request.approved">
          </div>

          <div class="block-wrapper text-center" style="min-width: 120px;" *ngIf="request.approved || request.declined">
            <p id="admin-onboarding-request-{{index}}-cc-approved" *ngIf="request.approved">{{ 'pages.default.administration.onboarding_requests.approved' | translate }}</p>
            <p id="admin-onboarding-request-{{index}}-cc-declined" *ngIf="request.declined">{{ 'pages.default.administration.onboarding_requests.declined' | translate }}</p>
          </div>

          <div class="block-action-wrapper justify-content-end" style="min-width: 120px;" *ngIf="!request.approved  && !request.declined && selected.length == 0">
            <a href="" class="block-action action-compact action-muted m-start-auto" [id]="'admin-onboarding-request-decline-for-' + request.uid" (click)="showDeclineModal($event, request)">
              <span [inlineSVG]="'/assets/svg/decline.svg'"></span>
            </a>
            <a href="" class="block-action action-compact" [id]="'admin-onboarding-request-approve-for-' + request.uid" (click)="showApproveModal($event, request)" >
              <span [inlineSVG]="'/assets/svg/approve.svg'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="layout-footer-pusher footer-paging"></div>
    <div class="layout-footer footer-paging">
      <div class="footer-content">
        <div class="container align-items-center">
          <!-- total items -->
          <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
          <!-- pagination -->
          <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
          <!-- pagination range -->
          <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
