import {Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EditConversationParticipantsModalComponent } from '../../modals/edit-conversation-participants-modal/edit-conversation-participants-modal.component';
import { EditConversationSubjectModalComponent } from '../../modals/edit-conversation-subject-modal/edit-conversation-subject-modal.component';
import { Conversation } from '../../models/conversation';
import { ConversationItem } from '../../models/conversation_item';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { ConversationService } from '../../services/conversation.service';
import { GeneralService } from '../../services/general.service';
import { HcpService } from '../../services/hcp.service';
import moment from 'moment';
import { ConversationParticipantsModalComponent } from '../../modals/conversation-participants-modal/conversation-participants-modal.component';
import { LocaleService } from '../../services/locale.service';
import { DateFormat } from '../../models/date-format';
import { AttentionCenterService } from '../../services/attention-center.service';
import { Attachment, Status } from '../../models/attachment';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-conversation-entries',
  templateUrl: './conversation-entries.component.html',
})
export class ConversationEntriesComponent implements OnInit, AfterViewInit {
  @Input() showEditBtns: Boolean;

  @Output() onAllEntriesRead: EventEmitter<any> = new EventEmitter();

  @ViewChild('newMsg') newMsgField: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  @ViewChild('end') public viewportRef!: ElementRef;

  public _conversation: Conversation;
  @Input('conversation')
  public set conversation(_convo: Conversation) {
    if(_convo) {
      const isNew = (_convo?.uid !== this.conversation?.uid) as boolean;

      this._conversation = _convo;

      if(isNew) {
        this.newMsgInput = null;
        this.pendingAttachments = [];
      }

      if(isNew || this._conversation.unread_messages) {
        this.getConversationEntries(0, isNew);
      }
    }
  };
  public get conversation():Conversation {
    return this._conversation;
  };

  public loadingPageIndex: number = null;
  public newMsgInput;
  public newMsgMaxLength = 1000;
  public itemsPagination = {};
  public visibleParticipantsNum = 3;
  public isConversationLoading = false;
  public time_24_hours = false;
  public time_zone: string;
  public currentHcp: HealthCareProfessional;
  public dateFormat: DateFormat;
  public extensionList: string;
  public pendingAttachments: Attachment[];
  public savedScrollPosition: number;

  public isProcessingAttachments: boolean;
  public isAddingAttachments: boolean;

  public convoItemHover: ConversationItem;
  public convoItemOptionsOpen: ConversationItem;

  constructor(
    public conversationService: ConversationService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public hcpService: HcpService,
    public modalService: BsModalService,
    public generalService: GeneralService,
    public localeService: LocaleService,
    public attentionCenterService: AttentionCenterService
  ) {
  }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.currentHcp = this.hcpService.getCurrentStoredHcp();
    this.extensionList = this.generalService.allSupportedTypes.fileInputAccept;
  }

  ngAfterViewInit() {}

  onScrollItems($event) {
    const current_page = this.itemsPagination['current_page'];
    const total_pages = this.itemsPagination['total_pages'];

    if ((current_page < (total_pages - 1 )) && this.loadingPageIndex === null) {
      const new_page = current_page + 1;

      this.saveScrollPosition();

      this.getConversationEntries(new_page, false);
    }
  }

  saveScrollPosition() {
    let containers:HTMLCollection = document.getElementsByClassName('conversation_items');
    let container:Element = containers[0];
    this.savedScrollPosition = container.scrollHeight;
  }

  isSender(item: ConversationItem) {
    return item.created_by === this.currentHcp.uid;
  }

  differentDate(current: ConversationItem, previous: ConversationItem) {
    return !moment(current.created_at).isSame(previous.created_at, 'day');
  }

  trackByFn(index, item) {
    return item.created_at;
  }

  public get canAddMessage():boolean {
    if(this.isProcessingAttachments) {
      return false;
    }

    let messageStr:string = this.messageInput;

    if(messageStr && messageStr.length>0) {
      return true;
    } else if (this.pendingAttachments && this.pendingAttachments.length > 0  && !this.pendingAttachments.some((attachtment: Attachment) => attachtment.status === Status.TOO_LARGE)) {
      return true;
    } else {
      return false;
    }
  }

  public get messageInput(): string {
    let messageStr:string = this.newMsgInput;

    if(messageStr) {
      messageStr = messageStr.trim();
    }

    return messageStr;
  }

  addMessage(): void {
    if(!this.canAddMessage) {
      return;
    }

    if(this.pendingAttachments && this.pendingAttachments.length>0) {
      this.addAttachments();
      return;
    }

    let messageStr:string = this.messageInput;

    if (messageStr) {
      this.conversation.latest_message_type = 'MESSAGE';
      this.conversation.latest_message = messageStr;
      this.conversation.draft = false;

      this.conversationService.addConversationEntry(this.conversation.uid, messageStr).subscribe(
        () => this.actionsAfterSubmit(),
        () => this.onAddMessageError()
      );
    } else {
      this.newMsgInput = '';
    }
  }

  addAttachments() {
    var toSaveAttachments = this.pendingAttachments.filter(att => att.status === "UNSAVED");

    let messageStr:string = this.messageInput;

    if(!toSaveAttachments?.length) {
      this.onAddMessageError();
      return false;
    }

    this.isAddingAttachments = true;

    let last_message: string = messageStr;
    let latest_message_type: string;

    const queued = from(toSaveAttachments)
    .pipe(mergeMap(att => {
      if(toSaveAttachments.indexOf(att) === (toSaveAttachments.length-1)) {
        this.conversation.latest_message = messageStr;
        latest_message_type = att.isAudio ? 'AUDIO_MESSAGE' : 'FILE_MESSAGE';
        return this.conversationService.addConversationEntry(this.conversation.uid, messageStr, att);
      } else {
        return this.conversationService.addConversationEntry(this.conversation.uid, null, att);
      }
    }, null, 1));

    return queued.subscribe(() => {
      this.conversation.latest_message = last_message;
      this.conversation.latest_message_type = latest_message_type;

      this.actionsAfterSubmit();
    },
    () => {},
    () => {
      this.isAddingAttachments = false;
    });
  }

  actionsAfterSubmit(syncEntries: boolean = true) {
    this.newMsgInput = '';
    this.pendingAttachments = [];

    if (this.newMsgField) {
      this.newMsgField.nativeElement.focus();
    }

    setTimeout(() => {
      this.scrollToConversationEnd();
    });

    if(syncEntries) {
      this.getConversationEntries(0, false);
    }
  }

  deleteMessage(event: MouseEvent, item: ConversationItem) {
    event?.preventDefault();

    this.conversationService.deleteConversationEntry(this.conversation.uid, item.id).subscribe(() => {
      item.type = 'MESSAGE_REMOVED';
      this.conversation.latest_message_type = item.type;
    });
  }

  onAddMessageError() {
    this.toastrService.error(this.translate.instant('form.feedback.something_went_wrong'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  getConversationEntries(page = 0, showConversationLoading = true) {
    if(showConversationLoading) {
      this.isConversationLoading = true;
    }

    this.loadingPageIndex = page;

    if (this.conversation?.patient) {
      this.visibleParticipantsNum = 2;
    } else {
      this.visibleParticipantsNum = 3;
    }

    if(page === 0) {
      this.savedScrollPosition = undefined;
    }

    setTimeout(() => {
      this.conversationService.getConversationEntries(this.conversation, page).subscribe(result => {
        this.loadingPageIndex = null;
        this.itemsPagination = result['pagination'];
        this.isConversationLoading = false;

        this.syncUnreadCount();

        setTimeout(() => {
          if (page === 0) {
            this.scrollToConversationEnd();
          } else {
            this.restoreScrollPosition();
          }
        });
      });
    }, 500);
  }

  syncUnreadCount() {
    if(!this.conversation.unread_messages) {
      return;
    }

    this.attentionCenterService.refresh();

    this.conversationService.getConversation(this._conversation.uid).subscribe(_convo => {
      this.conversation.unread_messages = _convo.unread_messages;

      if(!this.conversation.unread_messages) {
        this.onAllEntriesRead.emit();
      }
    });
  }

  scrollToConversationEnd() {
    let itemsContainers:HTMLCollection = document.getElementsByClassName('conversation_items');
    let itemsContainer:Element = itemsContainers[0];

    if(itemsContainer?.scroll) {
      itemsContainer.scroll(0, itemsContainer.scrollHeight);
    }
  }

  restoreScrollPosition() {
    let itemsContainers:HTMLCollection = document.getElementsByClassName('conversation_items');
    let itemsContainer:Element = itemsContainers[0];

    if(itemsContainer?.scroll) {
      let newLocation = (itemsContainer?.scrollHeight - this.savedScrollPosition);
      itemsContainer.scroll(0, newLocation);
    }
  }

  differentPage(current: ConversationItem, previous: ConversationItem) {
    return current.page !== previous.page;
  }

  loadingPage(page) {
    return (page + 1) === this.loadingPageIndex;
  }

  showEditSubjectModal(event) {
    event?.preventDefault();

    const initialState = {
      conversation: this.conversation
    };

    const modal = this.modalService.show(EditConversationSubjectModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modal.content.subjectEditedEvent.subscribe(() => {
      this.getConversationEntries(0, false);
    });
  }

  showEditParticipantsModal(event) {
    event?.preventDefault();

    const initialState = {
      conversation: this.conversation
    };

    const modal = this.modalService.show(EditConversationParticipantsModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modal.content.onParticipantsChange.subscribe(() => {
      this.getConversationEntries();
    });
  }

  showParticipantsModal(event, participants) {
    event.preventDefault();
    const initialState = {
      participants
    };

    this.modalService.show(ConversationParticipantsModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-compact',
        initialState
      })
    );
  }

  onEntryMouseEnter(item: ConversationItem) {
    if(this.convoItemHover !== item && this.isSender(item)) {
      this.convoItemHover = item;
    }
  }

  onEntryMouseLeave(item: ConversationItem) {
    if(this.convoItemHover == item) {
      this.convoItemHover = null;
    }
  }

  public mustShowItemOptions(item: ConversationItem): Boolean {
    if(item.type === 'MESSAGE_REMOVED') {
      return false;
    }

    if(this.convoItemHover == item || this.convoItemOptionsOpen == item) {
      return true;
    } else {
      return false;
    }
  }

  openAttachment(event: MouseEvent, convoItem: ConversationItem) {
    event?.preventDefault();
    this.conversationService.getDownloadLink(this._conversation.uid, convoItem.id).subscribe(link => window.location.href = link);
  }

  handleAttachmentInput(files: Array<any>) {
    if(!this.pendingAttachments) {
      this.pendingAttachments = [];
    }

    Array.from(files).forEach(file => this.fileInputToAttachments(file));

    this.postAttachments();

    if(this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = "";
    }
  }

  fileInputToAttachments(file: any) {
    const attachment = new Attachment(file);
    attachment.meta.created_at = moment().format();
    attachment.meta.file_name = file?.name;
    this.pendingAttachments.push(attachment);
  }

  postAttachments() {
    this.isProcessingAttachments = true;

    const waiting = this.pendingAttachments.filter(a => { return a.status === "WAITING" });
    if (waiting && waiting[0]) {
      this.postAttachment(waiting[0]);
    } else {
      this.isProcessingAttachments = false;
    }
  }

  postAttachment(attachment: Attachment) {
    attachment.meta.size = attachment.file.size;
    attachment.meta.extension = attachment.file.type.replace('image/', '');

    if (attachment.file.size > 20971520) {
      attachment.status = "TOO_LARGE";
      return;
    }

    this.conversationService.postAttachment(this.conversation.uid, attachment.file).subscribe(
      result => this.postAttachmentSuccess(result, attachment),
      error => this.postAttachmentError(error, attachment)
    );
  }

  postAttachmentSuccess(result, attachment) {
    attachment.status = "UNSAVED";
    attachment.uid = result['uid'];
    this.postAttachments();
  }

  postAttachmentError(error, attachment) {
    if (error['error'] && error['error']['errors'] && error['error']['errors'][0]['key'] === 'ERROR_FILE_INVALID_EXTENSION') {
      attachment.status = "INCONSISTENT_MIME_TYPE";
    } else {
      attachment.status = "ERROR";
    }
    this.postAttachments();
  }

  setStateToDeleted(event: MouseEvent, attachment: Attachment) {
    event?.preventDefault();
    this.pendingAttachments.splice(this.pendingAttachments.indexOf(attachment), 1);
  }
}
