import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Profile } from '../models/profile';
import { PasswordPolicy } from '../models/password-policy';
import { Consent } from '../models/consent';
import { ConsentService } from './consent.service';
import { LocaleService } from './locale.service';
import { AuthenticationService } from './authentication.service';
import { ApiService } from './api.service';
import { Permissions } from '../models/permissions';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './language.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MfaMethod } from '../models/mfa-method';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { GeneralService } from './general.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {
  public static StorageKeyProfile = 'C4T_profile';
  public static StorageKeyPermissions = 'C4T_permissions';

  private readonly platformUrl: string;

  constructor(
    private readonly consentService: ConsentService,
    http: HttpClient,
    public localeService: LocaleService,
    public authenticationService: AuthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public modalService: BsModalService,
    public toastrService: ToastrService,
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getProfile(): Observable<Profile> {
    return new Observable((observer) => {
      const url = `${this.platformUrl}/user/profile`;
      this.authenticatedGet(url, 'v2').subscribe(result => {
        const profile = new Profile(result);

        localStorage.setItem(UserService.StorageKeyProfile, JSON.stringify(profile));

        if (profile.locale) {
          this.localeService.setLocalePreferences(profile.locale);
        }

        observer.next(profile);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getStoredProfile(): Profile {
    const storedProfileString = localStorage.getItem(UserService.StorageKeyProfile);

    return storedProfileString != null ? (new Profile(JSON.parse(storedProfileString))) : null;
  }

  getPermissions(): Observable<Permissions> {
    return new Observable((observer) => {
      const url = `${this.platformUrl}/user/permissions`;
      this.authenticatedGet(url).subscribe(result => {
        sessionStorage.setItem(UserService.StorageKeyPermissions, JSON.stringify(result));
        observer.next(new Permissions(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getStoredPermissions(): Permissions {
    const storedPermissions = sessionStorage.getItem(UserService.StorageKeyPermissions);

    if (storedPermissions) {
      const result = JSON.parse(storedPermissions);
      return new Permissions(result);
    } else {
      return null;
    }
  }

  passwordPolicy(): Observable<any> {
    return new Observable((observer) => {
      const url = `${this.platformUrl}/user/password-policy`;
      this.authenticatedGet(url).subscribe(result => {
        const policy = new PasswordPolicy(result);
        observer.next(policy);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });

  }

  changePassword(old_password: string, new_password: string, new_password_verification: string): Observable<any> {
    return new Observable((observer) => {
      const url = `${this.platformUrl}/user/change-password`;
      const params = {
        old_password,
        new_password,
        new_password_verification
      };
      this.authenticatedPut(url, params).subscribe(result => {
        observer.next(result);
      }, error => {
        observer.error(error);
      });
    });
  }

  updateProfile(profile: Profile): Observable<Profile> {
    return new Observable((observer) => {
      const url = this.platformUrl + '/user/profile';
      const params = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        locale: {
          language: profile.locale.language,
          time_24_hours: profile.locale.time_24_hours,
          measurement_unit: profile.locale.measurement_unit,
          number_format: profile.locale.number_format,
          date_format: profile.locale.date_format,
          first_day_of_week: profile.locale.first_day_of_week,
          notification_channels: profile.locale.notification_channels,
          time_zone: profile.locale.time_zone
        },
        mfas: profile?.mfas?.map((_mfa) => {
          return {
            "channel": _mfa.channel,
            "primary": _mfa.primary,
          }
        }),
        mfa_enabled: profile?.mfa_enabled
      };

      if (profile.mobile_verification_code) {
        params['mobile_verification_code'] = profile.mobile_verification_code;
      }

      if (profile.mobile_number?.code && profile.mobile_number?.number) {
        params['mobile_number'] = {};
        params['mobile_number']['code'] = profile.mobile_number.code;
        params['mobile_number']['number'] = profile.mobile_number.number;
      }

      if (profile.contact_channel) {
        params['contact_channel'] = profile.contact_channel;
      }

      this.authenticatedPut(url, params, 'v2').subscribe(result => {
        const updatedProfile = new Profile(result);

        if (updatedProfile.locale) {
          updatedProfile.locale.language = profile.locale.language; //since this comes back incorrectly from time to time.
          this.localeService.setLocalePreferences(updatedProfile.locale);
        }

        localStorage.setItem(UserService.StorageKeyProfile, JSON.stringify(updatedProfile));

        observer.next(updatedProfile);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  rightOfRestriction(password: string, reasonType: string, reasonDescription: string): Observable<any> {
    const url = `${this.platformUrl}/user/gdpr/right-of-restriction`;
    const params = {
      password,
      user_confirmed: true,
      reason_type: reasonType,
      reason_description: reasonDescription
    };

    return this.authenticatedPost(url, params);
  }

  changeEmail(new_email: string, code?: string): Observable<any> {
    const url = this.platformUrl + '/user/change-email';
    const params = { new_email};

    return this.authenticatedPost(url, params);
  }

  verifyMobileNumber(mobile_number: {
    code: string,
    number: string
  }): Observable<any> {
    const url = this.platformUrl + '/user/verify-mobile-number';
    return this.authenticatedPost(url, {
      mobile_number: mobile_number
    });
  }

  rightOfErasure(password: string): Observable<any> {
    const url = `${this.platformUrl}/user/gdpr/right-of-erasure`;
    const params = {
      password,
      user_confirmed: true
    };
    return this.authenticatedPost(url, params);
  }

  rightOfPortability(): Observable<any> {
    const url = `${this.platformUrl}/user/gdpr/right-of-portability`;
    const params = {
      user_confirmed: true
    };
    return this.authenticatedPost(url, params);
  }

  verifyChangeEmailCode(verification_code: string): Observable<any> {
    return new Observable(observer => {
      const url = this.platformUrl + '/user/change-email';
      const params = { verification_code};

      return this.authenticatedPut(url, params).subscribe(
        result => {
          this.getProfile().subscribe();

          observer.next(result);
          observer.complete();
        },
        error => {
          observer.error(error);
          observer.complete();
        }
      );
    });
  }

  public removeMfaMethodeModal(mfaMethod: MfaMethod): Observable<boolean> {
    const initialState = {
      title: this.translate.instant('modals.mfa.remove_method'),
      description: this.translate.instant('modals.mfa.are_you_sure_remove_method', {
        value: mfaMethod.value
      }),
      no: this.translate.instant('action.cancel'),
      yes: this.translate.instant('modals.mfa.yes_remove'),
      isDanger: true
    };

    const modalref = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState,
        keyboard: false
      })
    );

    return new Observable(observer => {
      modalref.content.onChoice.subscribe(() => {
        modalref.content.showYesLoading = true;
  
        this.removeMfaMethode(mfaMethod).subscribe(
          () => {
            modalref.content.showYesLoading = false;
            modalref.hide();

            this.toastrService.info(this.translate.instant('modals.mfa.mfa_successfully_removed'), null, {
              disableTimeOut: false,
              timeOut: 4000
            });

            observer.next(true);
            observer.complete();
          },
          () => {
            observer.next(false);
            observer.complete();
          }
        );
      });

      modalref.content.onClose.subscribe(() => {
        observer.next(false);
        observer.complete();
      });
    });
  }

  removeMfaMethode(mfaMethod: MfaMethod): Observable<any> {
    const profile: Profile = this.getStoredProfile();
    
    profile.mfas = profile.mfas.filter((_mfa) => {
      return _mfa.channel !== mfaMethod.channel || mfaMethod.primary
    });

    return this.updateProfile(profile);
  }

  public setPrimaryMfaMethodModal(mfaMethod: MfaMethod): Observable<boolean> {
    
    const initialState = {
      title: this.translate.instant('modals.mfa.set_primary_method'),
      description: this.translate.instant('modals.mfa.are_you_sure_set_primary_method', {
        value: mfaMethod.value
      }),
      no: this.translate.instant('action.cancel'),
      yes: this.translate.instant('modals.mfa.yes_make_primary')
    };

    const modalref = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState,
        keyboard: false
      })
    );

    return new Observable(observer => {
      modalref.content.onChoice.subscribe(() => {
        modalref.content.showYesLoading = true;
  
        this.makePrimaryMfaMethod(mfaMethod).subscribe(
          () => {
            modalref.content.showYesLoading = false;
            modalref.hide();

            this.toastrService.info(this.translate.instant('modals.mfa.mfa_successfully_updated'), null, {
              disableTimeOut: false,
              timeOut: 4000
            });

            observer.next(true);
            observer.complete();
          },
          () => {
            observer.next(false);
            observer.complete();
          }
        );
      });

      modalref.content.onClose.subscribe(() => {
        observer.next(false);
        observer.complete();
      });
    }); 
  }

  makePrimaryMfaMethod(mfaMethod: MfaMethod): Observable<any> {
    const profile: Profile = this.getStoredProfile();

    profile.mfas = profile.mfas.map((_mfa) => {
      _mfa.primary = _mfa.channel === mfaMethod.channel
      return _mfa;
    });

    return this.updateProfile(profile);
  }
}
