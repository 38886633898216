<div class="calendar-appointment-compact">
  <div class="modal-content">

    <div class="modal-header">
      <h6 class="mb-0">{{ appointment?.title }}</h6>
      <button type="button" class="btn-close" id="appointment-compact-close" data-dismiss="modal" aria-label="Close"
          (click)="handleClose()"></button>
    </div>

    <div class="modal-body">
      <div class="mb-2">
        <p class="m-0" *ngIf="appointment.start_date && appointment.end_date">
          <span class="text-capitalize">{{ appointment.start_date | weekday }}</span>,
          {{ appointment.start_date | timeZoneDate }}
          {{ 'modals.appointment.from' | translate }}
          <span [dir]="languageService?.isHebrew ? 'ltr' : ''">{{ appointment?.start_date | time }} - {{ appointment?.end_date | time }}</span>
        </p>

        <p class="m-0" *ngIf="appointment.date">
          <span class="text-capitalize">{{ appointment.date | weekday }}</span>,
          {{ appointment.date | timeZoneDate }}
          <span class="text-info font-weight-bold m-start-1">
            {{ 'pages.default.calendar.full_day' | translate }}
          </span>
        </p>
      </div>

      <div class="mb-2" *ngIf="appointment?.location?.name">
        <p class="small text-muted mb-0">{{ 'modals.appointment.location' | translate }}</p>
        <p class="m-0" *ngIf="!appointment.location?.url">{{ appointment.location?.name }}</p>
        <p class="m-0" *ngIf="appointment.location?.url">
          <a [href]="appointment.location.url" class="text-info text-icon-link"
            id="calendar-appointment-compact-appointment-location" target="_blank">
            <span class="label">{{ appointment.location?.name }}</span>
            <span class="icon-end" [inlineSVG]="'/assets/svg/external.svg'"></span>
          </a>
        </p>

      </div>

      <div class="mb-2" *ngIf="appointment?.patient_invitee">
        <p class="small text-muted mb-0">{{ 'modals.appointment.patient' | translate }}</p>
        <p class="m-0">{{ appointment?.patient_invitee?.first_name }} {{ appointment?.patient_invitee?.last_name }}</p>
      </div>

      <div class="mb-2" *ngIf="getAllInviteeNames()">
        <p class="small text-muted mb-0">{{ 'modals.appointment.clinical_team' | translate }}</p>
        <p class="m-0">{{ getAllInviteeNames() }}</p>
      </div>

      <div class="mt-n2"></div>
    </div>

    <div class="modal-footer">
      <a href="" class="btn btn-link" id="calendar-appointment-compact-appointment-view-details"
        (click)="viewDetailsHandler($event)">
        <span class="label">{{ 'modals.appointment.view_details' | translate }}</span>
      </a>
    </div>
  </div>
</div>
