<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="!appointments || !appointments?.length">
      <span [inlineSVG]="'/assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6>{{ 'pages.default.calendar.no_appointments_yet' | translate }}</h6>
    </div>

    <div class="calendar-list" *ngIf="appointments?.length > 0">
      <div class="table-heading">
        <div class="d-flex align-items-center p-2 w-100">
<!--          TODO: Add new translations for Date and time, Patient, Title -->
          <div class="date-time">Date and time</div>
          <div class="text-value name sized-2">Patient</div>
          <div class="text-value title sized-3">Title</div>
        </div>
      </div>

      <ng-container *ngFor="let appointment of appointments; let i = index;">
        @if((appointment.status_of_user !== 'REMOVED') && (!appointment.hasRemovedState())) {

          <div class="heading" *ngIf="showHeading(i, appointment, appointments[i-1])">
            {{ ((appointment?.start_date)? appointment?.start_date : appointment?.date) | timeZoneDate:'MMMM' }}
          </div>

          <button type="button" id="item-{{ appointment.uid }}" class="item clickable btn w-100 text-align-start" (click)="selectAppointment($event, appointment)"
            [ngClass]="{
            'cancelled': appointment.hasCancelledState(),
            'highlight': appointment.hasNoneState()
            }">

            <div class="date">
              <p *ngIf="appointment.start_date">{{ appointment.start_date | timeZoneDate }}</p>
              <p *ngIf="appointment.date">{{ appointment.date | timeZoneDate }}</p>
            </div>

            <div class="time" [ngClass]="{'dir-ltr': languageService.isHebrew}">
              <p *ngIf="appointment?.start_date && appointment?.end_date">
                {{ appointment?.start_date | time }} - {{appointment?.end_date | time }}
              </p>
              <p class="full-day" [ngClass]="(appointment.hasCancelledState() ? 'text-muted' : 'text-info')" *ngIf="appointment?.date">
                {{ 'pages.default.calendar.full_day' | translate }}
              </p>
            </div>

            <div class="text-value name sized-2">
              <p>{{ appointment.patient_invitee?.getFullName() }}</p>
            </div>

            <div class="text-value title sized-3">
              <p>{{ appointment.title }}</p>
            </div>

            <div class="btn btn-sm btn-tertiary btn-icon">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/arrow-right-32.svg'"></span>
            </div>
          </button>
        }
      </ng-container>
    </div>
  </div>
</div>
