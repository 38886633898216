import { Component, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CareModule } from '../../../../models/care-module';
import { Patient } from '../../../../models/patient';
import { DateFormat } from '../../../../models/date-format';
import { LocaleService } from '../../../../services/locale.service';
import { PatientService } from '../../../../services/patient.service';
import { HospitalService } from '../../../../services/hospital.service';
import { assertNever } from '../../../../helpers/assert-never.helper';
import { AssignMdtModalComponent } from '../../../../modals/assign-mdt-modal/assign-mdt-modal.component';
import { GeneralService } from '../../../../services/general.service';
import { HcpService } from '../../../../services/hcp.service';
import { MedicalTeam } from '../../../../models/medical-team';
import { Pathway } from '../../../../models/pathway';
import { EditCmClModalComponent } from '../../../../modals/edit-cm-cl-modal/edit-cm-cl-modal.component';
import { ResendOnboardingEmailModalComponent } from '../../../../modals/resend-onboarding-email-modal/resend-onboarding-email-modal.component';
import { HealthCareProfessional } from '../../../../models/health-care-professional';
import { LanguageService } from '../../../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateNumberPipe } from '../../../../pipes/translate-number.pipe';
import { AddPatientWizardModalComponent } from '../../../../modals/add-patient-wizard-modal/add-patient-wizard-modal.component';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'app-admin-patients',
  templateUrl: './admin-patients.component.html',
  styleUrls: ['./admin-patients.component.scss']
})
export class AdminPatientsComponent implements OnInit {
  // readonly assignmentOptionsList: AssigmentOptionEnum[]
  //   = Object.keys(AssigmentOptionEnum).map(assignmentOption => AssigmentOptionEnum[assignmentOption]);

  selectedPatientsMap = new Map<Patient, boolean>();
  allPatients: Patient[] = [];
  selectedPatientUids: string[] = [];
  patientLastNameSearch = new Subject<string>();
  careModules: CareModule[] = [];
  dateFormat: DateFormat;
  hcpUid: string;
  hospitalUid: string;
  filters: any = {
    last_name: '',
    care_module_uid: null,
    assigned_mdt: null,
    assigned_surgery: null,
    status: null
  };
  filtersAreSet = false;
  moreFiltersAreShown = false;

  public assignmentOption = AssigmentOptionEnum.ANY_PENDING_ASSIGNMENT;

  public assignmentOptionsList = [
    { value: AssigmentOptionEnum.ACTIVE, translationKey: AssigmentOptionEnum.ACTIVE, id: 'admin-patients-change-assignment' },
    { value: AssigmentOptionEnum.ANY_PENDING_ASSIGNMENT, translationKey: AssigmentOptionEnum.ANY_PENDING_ASSIGNMENT, id: 'admin-patients-change-assignment' },
    { value: AssigmentOptionEnum.ONLY_MDT_NOT_ASSIGNED, translationKey: AssigmentOptionEnum.ONLY_MDT_NOT_ASSIGNED, id: 'admin-patients-change-assignment' },
  ];

  public sorting: string = 'last_name,asc;first_name,asc';
  public sortingOptions = [
    { value: 'last_name,asc;first_name,asc', translationKey: 'form.labels.last_name_sort_asc' },
    { value: 'last_name,desc;first_name,desc', translationKey: 'form.labels.last_name_sort_desc' },
    { value: 'created_at,asc', translationKey: 'form.labels.date_added_sort_asc' },
    { value: 'created_at,desc', translationKey: 'form.labels.date_added_sort_desc' }

  ];
  public currentPage = 1;
  public currentPageSize = 10;
  public totalPages: number;
  public totalElements: number;
  public isLoading = false;
  public selectedAll = false;

  constructor(
    public readonly localeService: LocaleService,
    public readonly hcpService: HcpService,
    public readonly patientService: PatientService,
    public readonly hospitalService: HospitalService,
    public readonly modalService: ModalService,
    public translateNumber: TranslateNumberPipe,
    public translateService: TranslateService,
    public languageService: LanguageService
  ) {
    this.patientLastNameSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.last_name = value;

        this.getPatients();
        this.filtersAreSet = true;
      });


      console.log('assignmentOptionsList');
      console.log(this.assignmentOptionsList);

      console.log('Object.keys(AssigmentOptionEnum)');
      console.log(Object.keys(AssigmentOptionEnum));

      console.log('AssigmentOptionEnum');
      console.log(AssigmentOptionEnum);


      console.log('this.assignmentOption');
      console.log(this.assignmentOption);
  }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.getScope();
  }

  private getScope(): void {
    this.hcpUid = this.hcpService.getCurrentStoredHcpUid();
    this.hospitalUid = this.hcpService.getCurrentStoredHospitalUid();

    this.isLoading = true;

    this.hospitalService.getCareModules(this.hospitalUid).subscribe(careModules => {
      this.careModules = careModules;
      this.filters.care_module_uid = careModules[0].uid;

      this.clearFilters();
      this.getPatients();
    });
  }

  getPatients(showLoading = true): void {
    if (showLoading) {
      this.isLoading = true;
      this.allPatients = [];
      this.selectedPatientsMap.clear();
    }

    this.patientService.getPatientsByHospital(this.hospitalUid, this.filters, this.sorting, this.currentPage - 1, this.currentPageSize)
      .subscribe(result => {

        this.allPatients = [];
        this.selectedPatientsMap.clear();
        for (const patient of result?.patients) {
          this.allPatients.push(patient);
          this.selectedPatientsMap.set(patient, false);
        }

        if (result.pagination) {
          this.totalPages = result.pagination.total_pages;
          this.totalElements = result.pagination.total_elements;
        }

        if (this.totalPages && this.currentPage && this.totalPages < this.currentPage) {
          this.currentPage = 1;
          this.getPatients();
        }

        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
  }

  onBulkAssignMdt($event: MouseEvent): void {
    $event.preventDefault();

    const selectedPatientList: Patient[] = [];
    this.selectedPatientsMap.forEach((selected: boolean, patient: Patient) => {
      if (selected) {
        selectedPatientList.push(patient);
      }
    });

    const careModule = this.careModules.filter(careModule => careModule.uid === this.filters.care_module_uid)[0]
    const initialState = {
      type: 'bulk-assign',
      patientList: selectedPatientList,
      careModule: careModule
    };

    const modalRef = this.modalService.show(AssignMdtModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState
      })
    );

    modalRef.content.assignmentSuccessEvent.subscribe((event) => {
      let mdtUids = [];
      if (event.addJobs) {
        mdtUids = event.addJobs?.filter(job => job instanceof MedicalTeam).map(mdt => mdt.uid)
      }

      selectedPatientList.forEach((patient: Patient) => {
        mdtUids.forEach((mdtUid) => {
          this.assignMdtUid(patient, careModule.uid, mdtUid)
        })
      });

      this.clearSelection();

    });
  }

  onEditCmCL($event: MouseEvent, type: 'CL' | 'CM') {
    $event.preventDefault();
    const selectedPatientList: Patient[] = [];
    const careModule = this.careModules.filter(careModule => careModule.uid === this.filters.care_module_uid)[0]

    this.selectedPatientsMap.forEach((selected: boolean, patient: Patient) => {
      if (selected) {
        selectedPatientList.push(patient);
      }
    });

    const initialState = {
      patients: selectedPatientList,
      type: type,
      careModule: careModule
    };

    const modalRef = this.modalService.show(EditCmClModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState
      })
    );

    modalRef.content.assignmentSuccessEvent.subscribe((event) => {
      const patients = event['patients'];
      const hcp: HealthCareProfessional = event['hcp'];
      const type: string = event['type'];

      this.applyClCmToPathways(hcp, patients, type);

      this.selectedPatientUids = [];
      this.selectedPatientsMap = new Map<Patient, boolean>();
    });

    modalRef.content.assignmentFailedEvent.subscribe((event) => {
      const patients = event['succeeded'];
      const failed = event['failed'];


      const hcp: HealthCareProfessional = event['hcp'];
      const type: string = event['type'];

      this.applyClCmToPathways(hcp, patients, type);

      this.selectedPatientUids = [];
      this.selectedPatientsMap = new Map<Patient, boolean>();

      failed.forEach(fail => {
        const patient: Patient = this.allPatients.find((p) => fail.uid === p.uid);
        this.selectedPatientsMap.set(patient, true);
        this.selectedPatientUids.push(patient.uid);
      });
    });
  }

  applyClCmToPathways(hcp, patients, type) {
    const careModule = this.careModules.filter(careModule => careModule.uid === this.filters.care_module_uid)[0]

    patients.forEach((patient: Patient) => {
      const pathway: Pathway = patient.pathways.find((_pathway: Pathway) => _pathway.care_module.uid === careModule.uid);

      if (type === 'CL') {
        pathway.clinical_lead = hcp;
      }

      if (type === 'CM') {
        pathway.case_manager = hcp;
      }
    });
  }

  onAddNewPatient($event: MouseEvent): void {
    $event.preventDefault();

    const modalRef = this.modalService.showWithInterceptor(
      AddPatientWizardModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
      }),
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: this.translateService.instant('modals.confirm_add_patient_wizard.title'),
          description: this.translateService.instant('modals.confirm_add_patient_wizard.description'),
          yes: this.translateService.instant('modals.confirm_add_patient_wizard.confirm'),
          no: this.translateService.instant('modals.confirm_add_patient_wizard.cancel')
        }
      })
    );

    modalRef.content.onPatientCreated.subscribe(() => {
      this.getPatients(false);
    });
  }

  toggleMoreFilters(setValue: boolean = null): void {
    this.moreFiltersAreShown = setValue ?? !this.moreFiltersAreShown;
  }

  onActionResetFilters(): void {
    this.clearFilters();
    this.getPatients();
  }

  private clearFilters(): void {
    this.selectedPatientUids = [];

    this.filters = {
      last_name: '',
      care_module_uid: this.careModules[0].uid,
      assigned_mdt: null,
      assigned_surgery: null,
      status: "CONSENT_PENDING"
    };

    // this.activateFirstCareModuleFilter();
    this.assignmentOption = AssigmentOptionEnum.ANY_PENDING_ASSIGNMENT;
    this.filtersAreSet = false;
  }

  onChangeSort(): void {
    this.getPatients();
  }

  onCareModuleFilterChange(): void {
    this.filtersAreSet = true;
    this.getPatients();
  }

  resendOnboardingEmail(event, patient: Patient): void {
    event.preventDefault();

    const initialState = {
      patient: patient
    };

    const modalRef = this.modalService.show(ResendOnboardingEmailModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalRef.content.onResend.subscribe(() => {
      patient.resend_onboarding_email_allowed = false;
    });
  }

  onPaginationPageChanged(page) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getPatients();
      this.clearSelection();
    }
  }

  onPaginationRangeChanged(range) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getPatients();
      this.clearSelection();
    }
  }

  onToggleCheckbox(patient: Patient): void {
    const patientWasSelected = this.selectedPatientsMap.get(patient);

    // update selected-list
    if (patientWasSelected) {
      // was true, will toggle to false
      const index = this.selectedPatientUids.indexOf(patient.uid);
      if (index > -1) {
        this.selectedPatientUids.splice(index, 1);
      }
    } else {
      this.selectedPatientUids.push(patient.uid);
    }

    // toggle select
    this.selectedPatientsMap.set(patient, !patientWasSelected);
  }

  onToggleAllCheckbox(): void {
    this.selectedAll = !this.selectedAll;

    for (const patient of this.allPatients) {
      this.selectedPatientsMap.set(patient, this.selectedAll);

      if (this.selectedAll) {
        this.selectedPatientUids.push(patient.uid);
      }
    }

    if (!this.selectedAll) {
      this.selectedPatientUids = [];
    }
  }

  getCareModuleForPatient(patient: Patient) {
    if (this.filters.care_module_uid) {
      const ccs = patient?.pathways?.filter(pathway => pathway.care_module.uid === this.filters.care_module_uid);

      if (ccs?.length) {
        return ccs[0];
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  clearSelection() {
    this.selectedPatientUids = [];
    this.selectedPatientsMap.clear();
  }

  assignMdtUid(patient: Patient, careModuleUid: string, mdtUid) {
    const patientMdt = patient?.pathways?.filter(pathway => pathway.care_module.uid === careModuleUid)[0]?.patient_mdt;
    patientMdt.mdt_uids.push(mdtUid);
  }

  hasAssignedMdt(patient: Patient): boolean {
    if (this.filters.care_module_uid) {
      const foundPathway: Pathway = patient?.pathways?.filter(pathway => pathway.care_module.uid === this.filters.care_module_uid)[0];
      return (foundPathway?.patient_mdt.mdt_uids?.length > 0) || (foundPathway?.patient_mdt.hcp_uids?.length > 0);
    } else {
      return false;
    }
  }

  onChangeAssignment(): void {
    this.allPatients = [];
    this.selectedPatientsMap.clear();
    this.selectedPatientUids = [];

    switch (this.assignmentOption) {
      case AssigmentOptionEnum.ANY_PENDING_ASSIGNMENT:
        this.filters.assigned_mdt = false;
        this.filters.assigned_surgery = false;
        this.filters.status = 'CONSENT_PENDING';
        break;
      case AssigmentOptionEnum.ONLY_MDT_NOT_ASSIGNED:
        this.filters.assigned_mdt = false;
        this.filters.assigned_surgery = null;
        this.filters.status = null;
        break;
      case AssigmentOptionEnum.ACTIVE:
        this.filters.assigned_mdt = null;
        this.filters.assigned_surgery = null;
        this.filters.status = 'ACTIVE';
        break;
      // case AssigmentOptionEnum.ONLY_SURGERY_NOT_ASSIGNED:
      //   this.filters.assigned_mdt = null;
      //   this.filters.assigned_surgery = false;
      //   break;
      default:
        assertNever(this.assignmentOption);
    }

    this.filtersAreSet = true;
    this.getPatients();
  }

  getLabelForTotalElements(): string {
    let translatedNumber = this.translateNumber.transform(this.totalElements);
    let itemsLabel = (this.totalElements > 1) ? this.translateService.instant('components.pagination.items_total') : this.translateService.instant('components.pagination.items_total_singular');

    if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1) {
      return itemsLabel + " " + translatedNumber;
    }

    return translatedNumber + " " + itemsLabel;
  }
}

enum AssigmentOptionEnum {
  ANY_PENDING_ASSIGNMENT = 'pages.default.administration.patients.any_pending_assignment',
  ONLY_MDT_NOT_ASSIGNED = 'pages.default.administration.patients.only_mdt_not_assigned',
  ACTIVE = 'pages.default.administration.patients.active',
  // ONLY_SURGERY_NOT_ASSIGNED = 'pages.default.administration.patients.only_surgery_not_assigned'
}

