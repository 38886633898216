import { Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select',
  templateUrl: './app-select.component.html',

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppSelectComponent),
      multi: true
    }
  ]
})
export class AppSelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  public set items(items) {
    if (this.orgItems !== items) {
      this.orgItems = items;
      this.rebuildItems();
    }
  }

  @Input()
  public set bindLabel(bindLabel: string) {
    if (this._bindLabel !== bindLabel) {
      this._bindLabel = bindLabel;
      this.bindCustomLabels();
    }
  }

  public get bindLabel() {
    return this._bindLabel;
  }

  public get hasValue(): boolean {
    return this.value !== undefined && this.value !== null && String(this.value).length > 0;
  }

  constructor(
    public translateService: TranslateService
  ) {
  }

  @Input() label: string;

  @Input() asFilter: boolean;
  @Input() showCheckboxes: boolean;
  @Input() showUserDetails: boolean;

  @Input() selectedItemsOnTop = true;
  @Input() autoSortOnLabel: boolean;
  @Input() showSortOptions: boolean;

  @Input() hideSelected: boolean;

  @Input() selectClass: string;

  @Output() search: EventEmitter<string> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<string> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<string> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() open: EventEmitter<string> = new EventEmitter();
  @Output() scrollToEnd: EventEmitter<string> = new EventEmitter();
  @Output() updateSortingDirection: EventEmitter<'asc' | 'desc'> = new EventEmitter();

  // pass through ng-select
  @Input() clearable: boolean;
  @Input() searchable: boolean;
  @Input() multiple: boolean;
  @Input() placeholder: string;
  @Input() bindValue;
  @Input() searchFn;
  @Input() readonly: boolean;
  @Input() loading: boolean;
  @Input() closeOnSelect = 'false';

  public orgItems: any[];
  public usedItems: any[];
  public disabled: boolean;

  public value;

  private _bindLabel: string;
  public onChange: any = () => {
  };
  public onTouched: any = () => {
  };

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe(() => {
      this.fillTranslatedLabel();
    });
  }

  writeValue(value: any): void {
    if (value != null && (value.length > 0 || typeof value !== 'string') ) {
      this.value = value;
    } else {
      this.value = null;
    }

    this.rebuildItems();
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onNgModelChange(e) {
    this.rebuildItems();
    this.onChange(e);
  }

  searchHandler(event?) {
    this.search.emit(event);
  }

  changeHandler(event?) {
    this.change.emit(event);
  }

  closeHandler(event?) {
    this.close.emit(event);
  }

  openHandler(event?) {
    this.open.emit(event);
  }

  scrollToEndHandler(event?) {
    this.scrollToEnd.emit(event);
  }

  rebuildItems() {
    this.usedItems = Object.assign([], this.orgItems);

    this.putSelectedItemsOnTop();

    this.bindCustomLabels();
  }

  putSelectedItemsOnTop() {
    if (this.selectedItemsOnTop) {
      if (Array.isArray(this.value)) {
        this.value.forEach(selectedItem => {
          if (this.isItemIncluded(selectedItem)) {
            const removed = this.usedItems.splice(this.getItemIndex(selectedItem), 1);
            this.usedItems = removed.concat(this.usedItems);
          }
        });
      } else {
        if (this.isItemIncluded(this.value)) {
          const removed = this.usedItems.splice(this.getItemIndex(this.value), 1);
          this.usedItems = removed.concat(this.usedItems);
        }
      }
    }
  }

  getItemIndex(singleSelectedItem): number {
    if (this.bindValue) {
      const found = this.usedItems.filter(_item => {
        return _item[this.bindValue] === singleSelectedItem;
      });

      if (found && found.length) {
        return this.usedItems.indexOf(found[0]);
      } else {
        return -1;
      }

    } else {
      return this.usedItems.indexOf(singleSelectedItem);
    }
  }

  isItemIncluded(singleSelectedItem): boolean {
    return (this.getItemIndex(singleSelectedItem) > -1);
  }

  bindCustomLabels() {
    if (!this.usedItems) {
      return;
    }

    if (!this.bindLabel) {
      this.fillTranslatedLabel();
      return;
    }

    const bindLabelParts: string[] = this.bindLabel.split('.');

    this.usedItems.forEach(_item => {
      let context = _item;

      if (context) {
        bindLabelParts.forEach(part => {
          if (context[part]) {
            context = context[part];
          }
        });
      }

      if (context) {
        _item.label = String(context);
      }
    });

    this.sortOnLabel();
  }

  fillTranslatedLabel() {
    const translateJobs = [];

    this.usedItems.forEach(_item => {
      // if(!_item.label && _item?.translationKey) {
      if (_item?.translationKey) {

        translateJobs.push({
          key: _item.translationKey,
          item: _item
        });
      }
    });

    if (translateJobs?.length) {
      this.translateService.get(translateJobs.map(i => i.key)).subscribe(_translations => {
        translateJobs.forEach((translateJob) => {
          translateJob.item.label = _translations[translateJob.key];
        });

        this.sortOnLabel();
      });
    } else {
      this.sortOnLabel();
    }
  }

  sortOnLabel() {
    if (this.autoSortOnLabel) {
      this.usedItems = this.usedItems.sort((a, b) => {
        if (Object.prototype.hasOwnProperty.call(a, 'label')) {
          if (a?.label < b?.label) {
            return -1;
          }
          if (a?.label > b?.label) {
            return 1;
          }
        }
        return 0;
      });

      this.usedItems = Object.assign([], this.usedItems);

      this.putSelectedItemsOnTop();
    }
  }

  sort(dir: 'asc' | 'desc') {
    this.updateSortingDirection.emit(dir);
  }
}
