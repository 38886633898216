<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-back-filters"></div>

  <div class="layout-header header-back-filters">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <p>
              <app-back-link route="/administration" label="action.back_to_administration" id="admin-mdts-back" />
            </p>
          </div>
          <div class="col col-auto">
            <a href="" class="btn btn-sm btn-secondary m-start-1" id="admin-mdts-add-mdt"
              (click)="onShowAddMdtModal($event)">{{ 'pages.default.administration.mdts.add_mdt' | translate }}</a>
          </div>
        </div>
      </div>

      <div class="container">
        <h1>{{ 'pages.default.administration.mdts.mdts' | translate }}</h1>
      </div>

      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">

            <div class="filters-group">

              <div class="form-group styled floating-label" style="width: 195px;">
                <input type="text" id="searchMdtName" class="form-control w-100" [placeholder]="('form.labels.mdt_name' | translate)"
                [(ngModel)]="filters.name"
                (ngModelChange)="searchMdtNameEvent.next($event)" required>
                <label for="searchMdtName">{{ 'form.labels.mdt_name' | translate }}</label>
              </div>

              <div class="divider"></div>

              <div class="form-group styled floating-label">
                <input type="text" id="searchHcpLastName" class="form-control w-100" [placeholder]="('form.labels.hcp_last_name' | translate)"
                [(ngModel)]="filters.hcpLastName"
                (ngModelChange)="searchHcpLastNameEvent.next($event)" required>
                <label for="searchHcpLastName">{{ 'form.labels.hcp_last_name' | translate }}</label>
              </div>

              <div class="divider d-none"></div>

              <app-select
                id="admin-mdts-select-function"
                [selectClass]="'d-none'"
                [placeholder]="('form.labels.function' | translate)"
                [clearable]="true"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="true"
                [items]="functions"

                [(ngModel)]="functionFilter"
                (ngModelChange)="onFunctionFilterChange($event)"

                [asFilter]="true"
                [showCheckboxes]="true"
              ></app-select>

              <div class="divider" *ngIf="filtersAreSet"></div>

              <button type="button" class="btn btn-link text-info py-0" id="admin-mdts-reset-filters" *ngIf="filtersAreSet" (click)="onResetFilters()">{{ 'action.reset' | translate }}</button>
            </div>

          </div>
          <div class="col col-auto">

            <div class="btn-group text-dropdown text-dropdown-sm" dropdown>
              <button id="dropdown-btn-sort" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                <span class="m-end-1">{{ 'general.sort_by' | translate }}:</span>

                <span *ngIf="sortDirectionAsc">{{ 'form.labels.name' | translate }} ({{ 'general.sort_dir_asc' | translate }})&#x200E;</span>
                <span *ngIf="!sortDirectionAsc">{{ 'form.labels.name' | translate }} ({{ 'general.sort_dir_desc' | translate }})&#x200E;</span>

                <span class="icon" [inlineSVG]="'/assets/svg/chevron-down.svg'"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-btn-sort">
                <li role="menuitem"><a class="dropdown-item" id="admin-mdts-sort-dir-asc" href="" (click)="onChangeSortDirectionAsc($event, true)">{{ 'form.labels.name' | translate }} ({{ 'general.sort_dir_asc' | translate }})&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" id="admin-mdts-sort-dir-desc" href="" (click)="onChangeSortDirectionAsc($event, false)">{{ 'form.labels.name' | translate }} ({{ 'general.sort_dir_desc' | translate }})&#x200E; </a></li>
              </ul>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="!isLoading">
      <div *ngFor="let mdt of mdts; let index = index;">
        <div id="mdt-{{index}}" class="action-item-block mb-2">
          <div class="block-wrapper px-3 pt-3 pb-1 w-100">
            <h3>{{mdt.name}}</h3>

            <div class="row">
              <div class="col-4 mb-2" *ngFor="let hcp of mdt.hcps">
                <div class="participant-item">
                  <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>
                  <div class="meta">
                    <p class="title">{{hcp.getFullName()}}</p>
                    <p class="description">{{ hcp.job_title }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a href="" class="block-action m-start-auto" (click)="onShowEditMdtModal($event, mdt)" [id]="'admin-mdts-edit-mdt-' + mdt.uid">
            <span class="icon" [inlineSVG]="'/assets/svg/edit.svg'"></span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="layout-footer-pusher footer-paging"></div>
    <div class="layout-footer footer-paging">
      <div class="footer-content">
        <div class="container align-items-center">
          <!-- total items -->
          <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
          <!-- pagination -->
          <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
          <!-- pagination range -->
          <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
