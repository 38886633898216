import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppointmentModalComponent } from './../../../modals/appointment-modal/appointment-modal.component';
import { AppointmentSelectionEvent } from '../../../events/appointment-selection-event';
import moment from 'moment';
import { UserService } from '../../../services/user.service';
import { DateFormat } from '../../../models/date-format';
import { Subject, Subscription } from 'rxjs';
import { LocaleService } from '../../../services/locale.service';
import { AppointmentService } from '../../../services/appointment.service';
import { Appointment } from '../../../models/appointment';
import { GeneralService } from '../../../services/general.service';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentFormModalComponent } from '../../../modals/appointment-form-modal/appointment-form-modal.component';
import { PatientService } from '../../../services/patient.service';
import { HealthCareProfessional } from '../../../models/health-care-professional';
import { Patient } from '../../../models/patient';
import { AuthenticationService } from '../../../services/authentication.service';
import { HcpService } from '../../../services/hcp.service';
import { AttentionCenterService } from '../../../services/attention-center.service';
import { ModalService } from '../../../services/modal.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
  public currentView: string;
  public showTreatmentsFilter: boolean;
  public disableTreatmentsFilter = true;
  public filtersAreSet: boolean;
  public showPendingInvitations: boolean;
  public pendingAppointments: Appointment[];
  public pendingAppointmentsFetchInterval: any;
  public patientFilter: Patient;
  public hcpFilter: HealthCareProfessional;
  public isLoadingPatients = false;
  public isLoadingHcps = false;

  public currentDay: any;
  public currentMonthFirstDay: any;
  public currentWeekFirstDay: any;
  public currentWeekLastDay: any;
  public currentYear: any;

  public hcps: HealthCareProfessional[];
  public patients: Patient[];
  public filters: any = {};

  public yearChanged: Subject<any> = new Subject();

  public compactAppointment: any;
  public compactAppointmentCoords: any = {
    x: 0,
    y: 0,
    downwards: false
  };

  public dateFormat: DateFormat;
  public time_24_hours: boolean;
  public timeZone: string;

  public onAppointmentChangeSubscription: Subscription;
  public onNewAppointmentsPendingSubscription: Subscription;
  public onAppointmentStatusChangeSubscription: Subscription;

  public searchPatientLastNameEvent = new Subject<{ term: string, items: any }>();
  public searchHcpLastNameEvent = new Subject<{ term: string, items: any }>();

  constructor(
    public modalService: ModalService,
    public userService: UserService,
    public localeService: LocaleService,
    public appointmentService: AppointmentService,
    public translate: TranslateService,
    public hcpService: HcpService,
    public patientService: PatientService,
    public authenticationService: AuthenticationService,
    public attentionCenterService: AttentionCenterService
  ) {
  }

  ngOnInit() {
    this.setLocale();
    this.loadLocalStorageVariables();
    this.pendingAppointmentsSetup();
    // this.getHCPs();
    // this.getPatients();
    this.setFilter();

    this.searchPatientLastNameEvent.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.getPatients(value);
      });

    this.searchHcpLastNameEvent.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.getHCPs(value);
      });


    this.onAppointmentChangeSubscription = this.appointmentService.onAppointmentChange.subscribe(appointment => this.whenAppointmentHasChanged(appointment));
    this.onAppointmentStatusChangeSubscription = this.appointmentService.onAppointmentStatusChanged.subscribe(() => this.loadPendingAppointments());
    this.onNewAppointmentsPendingSubscription = this.attentionCenterService.onNewAppointmentsPending.subscribe(() => this.loadPendingAppointments());

    this.appointmentService.onShowMoreEvents.subscribe((event) => this.onMoreEvents(event));
  }

  setFilter() {
    this.filters = [];
    this.filters.invitees_uid = [];

    if (this.hcpFilter) {
      this.filters.invitees_uid.push(this.hcpFilter.uid);
    }

    if (this.patientFilter) {
      this.filters.invitees_uid.push(this.patientFilter.uid);
    }

    this.filtersAreSet = (this.filters.invitees_uid.length > 0);
  }

  ngOnDestroy() {
    clearInterval(this.pendingAppointmentsFetchInterval);

    this.onAppointmentStatusChangeSubscription?.unsubscribe();
    this.onNewAppointmentsPendingSubscription?.unsubscribe();
  }

  getHCPs(event?) {
    let term = '';

    if (event) {
      if (event.term && event.term.length) {
        term = event.term;
      } else {
        this.hcps = [];
      }
    }

    this.isLoadingHcps = true;
    this.hcpService.getPaged({ last_name: term }, 'last_name,asc', 0, 50).subscribe({
      next: result => {
        this.hcps = result.items;
        this.isLoadingHcps = false;
      },
      error: () => this.isLoadingHcps = false
    });
  }

  getPatients(event?) {
    let term = '';

    if (event) {
      if (event.term && event.term.length) {
        term = event.term;
      } else {
        this.patients = [];
      }
    }

    this.isLoadingPatients = true;

    if (this.authenticationService.hasCcRole()) {
      this.patientService.getPatientsByHospital(
        this.hcpService.getCurrentStoredHospitalUid(),
        { last_name: term },
        'last_name,desc;first_name,desc',
        0,
        50
      ).subscribe({
        next: result => {
          this.patients = result.patients;
          this.isLoadingPatients = false;
        },
        error: () => this.isLoadingPatients = false
      });

    } else {
      this.patientService.getPatientsByHcp(
        this.hcpService.getCurrentStoredHcpUid(),
        { last_name: term },
        'last_name,desc;first_name,desc',
        0,
        50
      ).subscribe({
        next: result => {
          this.patients = result.patients;
          this.isLoadingPatients = false;
        },
        error: () => this.isLoadingPatients = false
      });
    }
  }

  whenAppointmentHasChanged(changedAppointment: Appointment) {
    let pendingAppointmentWasAffected = false;

    if (this.pendingAppointments && this.pendingAppointments.length) {
      this.pendingAppointments.forEach(pendingAppointment => {
        if (pendingAppointment.uid === changedAppointment.uid) {
          pendingAppointmentWasAffected = true;
        }
      });
    }

    if (pendingAppointmentWasAffected) {
      this.loadPendingAppointments();
    }
  }

  setLocale() {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.time_24_hours = this.localeService.getLocalePreferences().locale.time_24_hours;
    this.timeZone = this.localeService.getLocalePreferences().locale.time_zone;

    this.localeService.doLocaleConfiguration();
  }

  loadLocalStorageVariables() {
    this.currentView = localStorage.getItem('current_view');
    if (!this.currentView) {
      this.currentView = 'year';
      localStorage.setItem('current_view', this.currentView);
    }

    this.setCurrentDates();
  }

  setCurrentDates() {
    this.currentYear = moment().year();
    this.currentDay = moment();
    this.currentMonthFirstDay = moment(this.currentDay).startOf('month');
    this.currentWeekFirstDay = moment(this.currentDay).startOf('week');
    this.currentWeekLastDay = this.currentWeekFirstDay.clone().endOf('week');
    localStorage.setItem('current_year', this.currentYear);
  }

  changeViewTo(view: string) {
    this.setCurrentDates();
    this.currentView = view;
    localStorage.setItem('current_view', this.currentView);
  }

  toggleTreatmentsFilter(shown: boolean = undefined) {
    if (shown !== undefined) {
      this.showTreatmentsFilter = shown;
    } else {
      this.showTreatmentsFilter = !this.showTreatmentsFilter;
    }
  }

  resetFilters() {
    this.hcpFilter = null;
    this.patientFilter = null;
    this.setFilter();
  }

  clickOutsideHeaderFilters() {
    this.toggleTreatmentsFilter(false);
  }

  pendingAppointmentsSetup() {
    this.pendingAppointments = [];


    this.pendingAppointmentsFetchInterval = setInterval(() => {
      this.loadPendingAppointments();
    }, 60000);


    this.loadPendingAppointments();
  }

  loadPendingAppointments() {
    this.appointmentService.getPendingAppointments().subscribe(pendingAppointments => {
      this.pendingAppointments = pendingAppointments;
    });
  }

  openPendingAppointment(appointmentSelectionEvent: AppointmentSelectionEvent) {
    this.togglePendingInvitations(null, false);
    this.openAppointmentModal(appointmentSelectionEvent);
  }

  togglePendingInvitations(event, shown: boolean = undefined) {
    if (event) {
      event.preventDefault();
    }

    if (shown !== undefined) {
      this.showPendingInvitations = shown;
    } else {
      this.showPendingInvitations = !this.showPendingInvitations;
    }
  }

  clickOutsidePendingInvitations() {
    this.togglePendingInvitations(null, false);
  }

  openAppointmentCompact(appointmentSelectionEvent: AppointmentSelectionEvent) {
    const event = appointmentSelectionEvent.event;

    let downwards = false;
    let horSided = '';

    const verticalThreshold = (window.innerHeight / 2.5);
    const horizontalThreshold = 180;

    if (event.clientY < verticalThreshold) {
      downwards = true;
    }

    if (event.clientX <= horizontalThreshold) {
      horSided = 'start';
    } else if (event.clientX >= (window.innerWidth - horizontalThreshold)) {
      horSided = 'end';
    }

    this.compactAppointmentCoords = {
      x: event.clientX,
      y: event.clientY,
      downwards,
      horSided
    };

    if (this.compactAppointment !== appointmentSelectionEvent.context) {
      this.compactAppointment = appointmentSelectionEvent.context;
    } else {
      this.clearCompactComponent();
    }
  }

  clearCompactComponent() {
    this.compactAppointmentCoords = undefined;
    this.compactAppointment = undefined;
  }

  compactAppointmentViewDetails() {
    this.openAppointmentModal(new AppointmentSelectionEvent(null, this.compactAppointment));
    this.clearCompactComponent();
  }

  openAppointmentModal(appointmentSelectionEvent: AppointmentSelectionEvent) {
    const initialState = {
      appointment: appointmentSelectionEvent.context,
      dateFormat: this.dateFormat,
      time_24_hours: this.time_24_hours
    };

    const modalref = this.modalService.show(AppointmentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );
  }

  nextMonth() {
    this.currentMonthFirstDay = moment(this.currentMonthFirstDay).add(1, 'M').startOf('month');
    localStorage.setItem('current_month_first_day', this.currentMonthFirstDay);
  }

  prevMonth() {
    this.currentMonthFirstDay = moment(this.currentMonthFirstDay).add(-1, 'M').startOf('month');
    localStorage.setItem('current_month_first_day', this.currentMonthFirstDay);
  }

  nextWeek() {
    this.currentWeekFirstDay = moment(this.currentWeekFirstDay).add(1, 'w').startOf('week');
    this.currentWeekLastDay = this.currentWeekFirstDay.clone().endOf('week');

    localStorage.setItem('current_week_first_day', this.currentWeekFirstDay);
  }

  prevWeek() {
    this.currentWeekFirstDay = moment(this.currentWeekFirstDay).add(-1, 'w').startOf('week');
    this.currentWeekLastDay = this.currentWeekFirstDay.clone().endOf('week');

    localStorage.setItem('current_week_first_day', this.currentWeekFirstDay);
  }

  nextYear() {
    this.currentYear = (moment('1/1/' + this.currentYear).add(1, 'y')).year();
    localStorage.setItem('current_year', this.currentYear);
    this.yearChanged.next(this.currentYear);
  }

  prevYear() {
    this.currentYear = (moment('1/1/' + this.currentYear).subtract(1, 'y')).year();
    localStorage.setItem('current_year', this.currentYear);
    this.yearChanged.next(this.currentYear);
  }

  addAppointment($event) {
    $event.preventDefault();
    const initialState = {
      appointment: new Appointment()

    };

    const modalref = this.modalService.showWithInterceptor(AppointmentFormModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );
  }

  onMoreEvents(date: Date) {
    if (this.currentView !== 'year') {
      this.changeViewTo('year');
      setTimeout(() => this.appointmentService.showMoreEventsInYear(date));
    }
  }

  selectAllTreatments() {
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }
}
