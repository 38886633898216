import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QueryList, QueryListStatus } from '../../models/query-list';
import { HcpService } from '../../services/hcp.service';
import { QueryListService } from '../../services/query-list.service';

@Component({
  selector: 'app-patient-detail-querylists',
  templateUrl: './patient-detail-querylist.component.html'
})
export class PatientDetailQuerylistComponent implements OnChanges {
  public QueryListStatus = QueryListStatus;
  public hcpUid: string;

  @Input() patientUid: string;
  @Input() pathwayUid: string;
  @Input() type: 'QUESTIONNAIRE' | 'CHECKLIST';

  queryLists: QueryList[];
  loading: boolean;
  totalChecklists: number;


  constructor(
    private hcpService: HcpService,
    public queryListService: QueryListService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pathwayUid) {
      this.loadChecklists();
    }
  }

  loadChecklists(showLoading = true) {
    if (showLoading) {
      this.queryLists = [];
      this.loading = true;
    }

    const checklistsObservable = this.queryListService.getQueryLists(
      this.hcpService.getCurrentStoredHcpUid(),
      this.patientUid,
      this.pathwayUid,
      {query_list_type: this.type},
      'status,desc,changed_at,desc',
      0,
      3
    );

    checklistsObservable.subscribe({
      next: result => {
        this.loading = false;
        this.queryLists = result.queryLists;
        this.totalChecklists = result.pagination.total_elements;
      },
      error: () => this.loading = false
    });
  }
}
