<div class="modal-body">
  <button (click)="onHandleClose()" aria-label="Close" class="close" id="add-hcp-modal-close" data-dismiss="modal" type="button">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.add_hcp.title' | translate }}</h2>

  <form *ngIf="form" [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">

    <!-- Gender -->
    <div class="pb-2">
      <div class="form-group">
        <fieldset>
          <legend class="small d-block required">{{ 'form.labels.gender' | translate }}</legend>

          <div class="form-check">
            <input class="form-check-input" formControlName="gender" id="radioSexMale" type="radio" value="MALE">
            <label class="form-check-label" for="radioSexMale">{{ 'form.labels.male' | translate }}</label>
          </div>

          <div class="form-check">
            <input class="form-check-input" formControlName="gender" id="radioSexFemale" type="radio" value="FEMALE">
            <label class="form-check-label" for="radioSexFemale">{{ 'form.labels.female' | translate }}</label>
          </div>

          <div class="form-check">
            <input class="form-check-input" formControlName="gender" id="radioSexOther" type="radio" value="UNKNOWN">
            <label class="form-check-label" for="radioSexOther">{{ 'form.labels.other' | translate }}</label>
          </div>
        </fieldset>

        <app-feedback-field [field]="form.get('gender')" [extraClass]="'general-feedback'"/>
      </div>
    </div>

    <!-- First name -->
    <div class="form-group styled floating-label">
      <input [placeholder]="('form.labels.first_name' | translate)" class="form-control w-100" formControlName="first_name"
             id="inputFirstName" type="text">
      <label class="required" for="inputFirstName">{{ 'form.labels.first_name' | translate }}</label>
      <app-feedback-field [field]="form.get('first_name')"/>
    </div>

    <!-- Last name -->
    <div class="form-group styled floating-label">
      <input [placeholder]="'form.labels.last_name' | translate" class="form-control w-100" formControlName="last_name"
             id="inputLastName" type="text">
      <label class="required" for="inputLastName">{{ 'form.labels.last_name' | translate }}</label>
      <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
    </div>

     <!-- Email -->
     <div class="form-group styled floating-label">
      <input [placeholder]="'form.labels.email_address' | translate" class="form-control w-100"
             formControlName="email" id="inputEmail" type="text">
      <label for="inputEmail" class="required">{{ 'form.labels.email_address' | translate }}</label>
      <app-feedback-field [field]="form.get('email')"></app-feedback-field>
    </div>

    <!-- Phone number -->
    <div class="row validation-group" formGroupName="phone_number">
      <div class="col-5" [ngClass]="{'order-last': languageService.isHebrew}">
        <div class="form-group styled floating-label required">
          <app-select
            [placeholder]="('form.labels.country_code' | translate)"
            [clearable]="true"
            [searchable]="true"
            [hideSelected]="false"
            [multiple]="false"
            [items]="dialCodeOptions"
            [bindValue]="'value'"

            [autoSortOnLabel]="true"
            [selectedItemsOnTop]="false"

            formControlName="code"

            [asFilter]="false"
            [showCheckboxes]="false"
          ></app-select>
           <app-feedback-field [field]="form.get('phone_number.code')"></app-feedback-field>

        </div>
      </div>
      <div class="col-7" [ngClass]="{'order-first': languageService.isHebrew}">
        <div class="form-group styled floating-label">
          <input [placeholder]="('form.labels.phone_number' | translate)" class="form-control w-100"
                 formControlName="number" id="inputPhone" type="tel">
          <label for="inputPhone" class="required">{{ 'form.labels.phone_number' | translate }}</label>
        </div>
      </div>
    </div>
    <app-feedback-field [field]="form.get('phone_number')"></app-feedback-field>

    <!-- Language -->
    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.language' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="languageOptions"
        [bindValue]="'value'"

        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"

        formControlName="language"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
      <app-feedback-field [field]="form.get('language')"></app-feedback-field>
    </div>

    <!-- Function -->
    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.function' | translate)"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="functionOptions"
        [bindValue]="'value'"
        formControlName="function"
      ></app-select>
      <app-feedback-field [field]="form.get('function')"></app-feedback-field>
    </div>

    <div class="form-group styled floating-label">
      <input [placeholder]="'form.labels.job_title' | translate" class="form-control w-100" formControlName="job_title" id="jobTitle" type="text">
      <label class="required" for="jobTitle">{{ 'form.labels.job_title' | translate }}</label>
      <app-feedback-field [field]="form.get('job_title')"></app-feedback-field>
    </div>

    <!-- Location -->
    <div formGroupName="location">
      <div class="form-group styled floating-label">
        <input [placeholder]="'form.labels.location' | translate" class="form-control w-100" formControlName="name"
              id="inputLocation" type="text">
        <label for="inputLocation">{{ 'form.labels.location' | translate }}</label>
        <p class="validation-feedback" *ngIf="this.form?.controls?.location.get('name').errors?.maxlength">{{ 'form.feedback.maxlength' | translate : {value:50} }}</p>

      </div>

      <!-- Checkbox: use location URL -->
      <div class="form-group styled floating-label">
        <div class="form-check">
          <input class="form-check-input" id="checkboxUseLocationUrl"
                type="checkbox" formControlName="locationLink">
          <label class="form-check-label"
                for="checkboxUseLocationUrl">{{ 'modals.edit_hcp.include_location_url' | translate }}</label>
        </div>
      </div>

      <!-- Location URL -->
      <div  class="form-group styled floating-label" *ngIf="form.get('location').get('locationLink').value">
        <input [placeholder]="'form.labels.location_url' | translate" class="form-control w-100" formControlName="url"
              id="inputLocationUrl"
              type="text">
        <label for="inputLocationUrl">{{ 'form.labels.location_url' | translate }}</label>
      </div>
    </div>
  </form>
</div>


<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" id="add-hcp-modal-cancel">{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3" id="add-hcp-modal-submit">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
