<div class="banner-info-modal">
  <div class="modal-header">
    <h6 class="mb-0">{{ title }}</h6>
    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
  </div>

  <div class="modal-body">
    <div [innerHtml]="description" class="description"></div>
  </div>

  <div class="modal-footer no-shadow">
    <button class="btn btn-primary ms-auto" id="more-info-banner-modal-close" (click)="bsModalRef.hide()">
      {{ 'action.close' | translate }}
    </button>
  </div>
</div>
