<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-back"></div>

  <div class="layout-header header-back">
    <div class="header-content">
      <div class="container">
        <app-breadcrumb></app-breadcrumb>
      </div>

      <div class="container">
        <h1>{{ 'pages.default.administration.care_modules.care_modules' | translate }}</h1>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div *ngIf="isLoading" class="container">
      <app-page-loader></app-page-loader>
    </div>

    <div *ngIf="!isLoading" class="container">
      <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="!careModules || careModules.length <= 0">
        <span [inlineSVG]="'/assets/svg/package.svg'"></span>
        <h3>{{ 'pages.default.administration.care_modules.no_care_modules' | translate }}</h3>
      </div>

      <div class="single-item-block header-block mb-2" *ngIf="careModules && careModules.length > 0">
        <div class="row align-items-center w-100">
          <div class="col-4">
            <p class="small my-1 mx-2 text-truncate">{{ 'pages.default.administration.care_modules.name' | translate }}</p>
          </div>
          <div class="col-4">
            <p class="small my-1 mx-2 text-truncate">{{ 'pages.default.administration.care_modules.description' | translate }}</p>
          </div>
          <div class="col-2">
            <p class="small my-1 mx-2 text-truncate">{{ 'pages.default.administration.care_modules.linked_mdts' | translate }}</p>
          </div>
          <div class="col-2">
            <p class="small my-1 mx-2 text-truncate">{{ 'pages.default.administration.care_modules.care_module_type' | translate }}</p>
          </div>
        </div>
        <div style="min-width: 60px"></div>
      </div>

      <div *ngFor="let careModule of careModules; let index = index;" class="e2e-care-module-list">
        <div id="care-module-{{index}}" class="single-item-block mb-2">

          <div class="row align-items-center">
            <div class="col-4">
              <div class="block-wrapper">
                <p class="label text-truncate">{{ careModule.translationKey | translate }}</p>
              </div>
            </div>
            <div class="col-4">
              <div class="block-wrapper">
                <p class="text-dark text-truncate">
                  {{ careModule.descriptionTranslationKey | translate }}</p>
              </div>
            </div>

            <div class="col-2">
              <div class="block-wrapper">
                <p *ngIf="careModule.default_mdts?.length" class="text-dark text-truncate">
                  {{ careModule.default_mdts?.length }} {{ 'pages.default.administration.care_modules.linked' |
                  translate }}</p>
                <p *ngIf="careModule.default_mdts?.length < 1" class="text-info font-weight-bold">
                  {{ 'pages.default.administration.care_modules.none_linked' |
                  translate }}
                </p>
              </div>
            </div>

            <div class="col-2">
              <div class="block-wrapper">
                <p class="text-dark text-truncate">
                  {{ careModule.type | translate }}</p>
              </div>
            </div>
          </div>

          <div class="block-action-wrapper justify-content-end" style="min-width: 60px">
            <a class="block-action action-compact" id="care-module-detail-{{careModule.uid}}"
              [routerLink]="['/administration/care-modules/' + careModule?.uid]">
              <span [inlineSVG]="'/assets/svg/right-arrow-big-old.svg'" class="rtl-mirrored-inline-svg"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="layout-footer-pusher footer-paging"></div>
    <div class="layout-footer footer-paging">
      <div class="footer-content">
        <div class="container align-items-center">
          <!-- total items -->
          <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
          <!-- pagination -->
          <div *ngIf="totalPages > 1">
            <app-pagination [totalPages]="totalPages" [activePage]="currentPage"
              (pageChange)="onPaginationPageChanged($event)"></app-pagination>
          </div>
          <!-- pagination range -->
          <div class="m-start-auto">
            <app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)"
              [rangeOptions]="[5,10, 20]"></app-pagination-range>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
