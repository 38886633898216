<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo text-jnj" [inlineSVG]="'/assets/svg/logo-polyphonic.svg'"></div>
    </div>

    <div class="content-part" [ngClass]="{'validation-visible': showFieldValidation()}" *ngIf="view === 'CODE'">
      <div class="mb-3">
        <h6 *ngIf="currentMfaMethod?.channel === 'EMAIL'">{{ 'pages.flow.mfa_code.enter_security_code_email' | translate }}</h6>
        <h6 *ngIf="currentMfaMethod?.channel === 'SMS'">{{ 'pages.flow.mfa_code.enter_security_code_sms' | translate }}</h6>
        <p class="small">{{ 'pages.flow.mfa_code.subtitle' | translate }}</p>
      </div>

      <div class="form-group styled">
        <label for="input_mfa_code_security_code">{{ 'pages.flow.mfa_code.security_code' | translate }}</label>
        <input id="input_mfa_code_security_code" type="text" class="form-control w-100" [ngClass]="{'ng-invalid-important': showFieldValidation()}" [(ngModel)]="codeInput" autocomplete="off">
        <p id="feedback_mfa_code_code_invalid" class="validation-feedback general-feedback" *ngIf="codeIncorrect">{{ 'pages.flow.mfa_code.code_invalid' | translate }}</p>
        <p id="feedback_mfa_code_enter_security_code" class="validation-feedback general-feedback" *ngIf="codeEmpty && !codeInput">{{ 'pages.flow.mfa_code.enter_security_code' | translate }}</p>
      </div>

      <p class="mt-2 mb-0">
        <a href="" class="text-link" id="button_mfa_resend_code" (click)="submitRequestNewCode($event)">{{ 'pages.flow.mfa_code.resend_code' | translate }}</a>
        <span *ngIf="mfaMethods?.length>1">&nbsp;{{ 'pages.flow.mfa_code.or' | translate }}&nbsp;</span>
        <a href="" class="text-link" id="button_mfa_other_method" (click)="viewOtherMethods($event)" *ngIf="mfaMethods?.length>1">{{ 'pages.flow.mfa_code.use_a_backup_method' | translate }}</a>
      </p>

      <button id="button_mfa_code_submit" type="submit" class="btn btn-primary mt-2 mt-lg-3" [ngClass]="{'loader': isLoading}" (click)="submit()">
        <span class="loader" *ngIf="isLoading" [inlineSVG]="'/assets/svg/loader-dark-sm.svg'"></span>
        <span>{{ 'action.sign_in' | translate }}</span>
      </button>
    </div>

    <div class="content-part mt-auto" *ngIf="view === 'CODE'">
      <div class="info-box">
        <em class="info-box-icon" [inlineSVG]="'/assets/svg/info-circle.svg'"></em>
        <h6 class="info-box-title">{{ 'pages.flow.mfa_code.did_you_know' | translate }}</h6>
        <p>{{ 'pages.flow.mfa_code.did_you_know_text' | translate }}</p>

      </div>
    </div>

    <div class="content-part mb-auto" *ngIf="view === 'METHODS'">
      <div class="mb-3">
        <h6>{{ 'pages.flow.mfa_code.choose_backup_method' | translate }}</h6>
      </div>

      <p class="mb-2" *ngFor="let mfaMethod of mfaMethods">
        <a href="" class="text-icon-link hoverable text-secondary" (click)="selectMethod($event, mfaMethod)">
          <span class="label" *ngIf="mfaMethod?.channel === 'EMAIL'">{{ 'pages.flow.mfa_code.send_mail_to_input' | translate:{'input': mfaMethod?.value} }}</span>
          <span class="label" *ngIf="mfaMethod?.channel === 'SMS'">{{ 'pages.flow.mfa_code.send_sms_to_input' | translate:{'input': mfaMethod?.value}   }}</span>
          <em class="icon-end rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big-old.svg'"></em>
        </a>
      </p>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>

  <div class="layout-background"></div>
</div>
