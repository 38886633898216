<div class="row align-items-center justify-content-between mb-2" *ngIf="patients?.length > 0">
  <div class="col-auto">
    <h3 class="m-0" id="table-ref">{{ 'pages.default.analytics.pathways-overview.patient-list.title' | translate}}</h3>
  </div>
  <div class="col-auto">

    <div class="filters-group">
      <!--
      <a href="" class="btn-link text-secondary m-end-3">Export all...</a>
      <a href="" class="btn-link text-secondary m-end-3">Choose columns...</a>
      -->

      <div class="btn-group text-dropdown text-dropdown-sm d-none" dropdown>
        <button id="dropdown-btn-sort" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
          <span class="m-end-1">{{ 'general.sort_by' | translate }}:</span>

          <span *ngIf="sorting == 'last_name,asc'">{{ 'form.labels.last_name_sort_asc' | translate }}&#x200E;</span>
          <span *ngIf="sorting == 'last_name,desc'">{{ 'form.labels.last_name_sort_desc' | translate }}&#x200E;</span>
          <span *ngIf="sorting == 'pathway_started_at,asc'">{{ 'form.labels.pathway_started_at_asc' | translate }}&#x200E;</span>
          <span *ngIf="sorting == 'pathway_started_at,desc'">{{ 'form.labels.pathway_started_at_desc' | translate }}&#x200E;</span>

          <span class="icon" [inlineSVG]="'/assets/svg/chevron-down.svg'"></span>
        </button>

        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-btn-sort">
          <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'last_name', 'asc')">{{ 'form.labels.last_name_sort_asc' | translate }}&#x200E; </a></li>
          <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'last_name', 'desc')">{{ 'form.labels.last_name_sort_desc' | translate }}&#x200E; </a></li>
          <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'pathway_started_at', 'asc')">{{ 'form.labels.pathway_started_at_sort_asc' | translate }}&#x200E; </a></li>
          <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'pathway_started_at', 'desc')">{{ 'form.labels.pathway_started_at_sort_desc' | translate }}&#x200E; </a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<table class="table-items-list" aria-describedby="table-ref" *ngIf="patients?.length > 0">
<!--  TODO: .thead-light and .thead-dark are dropped in favor of the .table-* variant classes which can be used for all table elements (thead, tbody, tfoot, tr, th and td).  -->
  <thead class="thead-light">
    <tr>
      <th scope="col">{{ 'pages.default.analytics.pathways-overview.patient-list.table.patient' | translate}}</th>
      <th scope="col">{{ 'pages.default.analytics.pathways-overview.patient-list.table.start_date' | translate}}</th>
      <th scope="col">{{ 'pages.default.analytics.pathways-overview.patient-list.table.current_phase' | translate}}</th>
      <th scope="col" *ngFor="let measure of patients[0].measures">
        {{ measure.label.key | translate}}
      </th>
      <th scope="col text-end">{{ 'pages.default.analytics.pathways-overview.patient-list.table.actions' | translate}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let patient of patients">
      <!-- NOG TESTEN -->
      <td class="font-weight-bold text-truncate e2e-patient-fullname">
        <a href="" [routerLink]="['/patient/' + patient?.uid]" [queryParams]="{'back-to-url': '/analytics/pathways-overview'}">{{ patient.getFullName()}}</a>
      </td>
      <td class="e2e-patient-started_at">{{ patient.pathway_started_at | timeZoneDate }}</td>
      <td class="e2e-patient-phase text-truncate">{{ patient.phase.translationKey | translate }}</td>
      <td *ngFor="let measure of patient.measures" class="text-truncate e2e-patient-measure">
        {{ measure.value}}
        <ng-container *ngIf="measure.unit_of_value">
          {{ measure.unit_of_value?.key | translate }}
        </ng-container>
      </td>

      <td class="cell-actions">
        <!--
        <a href="" class="block-action action-muted">
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/chat.svg'"></span>
        </a>
      -->
        <a href="" class="block-action action-compact" [routerLink]="['/patient/' + patient?.uid]" [queryParams]="{'back-to-url': '/analytics/pathways-overview'}">
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big-old.svg'"></span>
        </a>
      </td>
    </tr>
  </tbody>
</table>

<ng-container *ngIf="!isLoading && totalElements">
  <div class="layout-footer-pusher footer-paging"></div>
  <div class="layout-footer footer-paging">
    <div class="footer-content">
      <div class="container align-items-center">
        <!-- total items -->
        <div class="m-end-auto" id="total-patients">{{ getLabelForTotalElements() }}</div>
        <!-- pagination -->
        <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
        <!-- pagination range -->
        <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
      </div>
    </div>
  </div>
</ng-container>
