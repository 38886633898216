<label>{{ label ?? placeholder }}</label>

<ng-select
  [class]="selectClass"
  [ngClass]="{ 'ng-select-as-filter' : asFilter, 'users-select' : showUserDetails, 'ng-select-filled': hasValue  }"
  [placeholder]="placeholder"
  [clearable]="clearable"
  [searchable]="searchable"
  [multiple]="multiple"
  [items]="usedItems"
  [loading]="loading || (!usedItems && !disabled)"
  [searchFn]="searchFn"
  [closeOnSelect]="closeOnSelect"
  [(ngModel)]="value"

  (ngModelChange)="onNgModelChange($event)"
  (search)="searchHandler($event)"
  (change)="changeHandler($event)"
  (close)="closeHandler($event)"
  (open)="openHandler($event)"
  (scrollToEnd)="scrollToEndHandler($event)"

  [disabled]="disabled"
  [readonly]="readonly"

  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
>

  @if (showSortOptions) {
    <ng-template ng-header-tmp class="ng-input-header d-none">

      <button type="button" (click)="sort('asc')" class="btn btn-tertiary fw-400">
        <span [inlineSVG]="'/assets/svg/arrow-up-24.svg'"></span>
        <!--    TODO: Add translations-->
        <span>Sort Ascending</span>
      </button>

      <button type="button" (click)="sort('desc')" class="btn btn-tertiary fw-400">
        <span [inlineSVG]="'/assets/svg/arrow-down-24.svg'"></span>
        <!--    TODO: Add translations-->
        <span>Sort Descending</span>
      </button>
    </ng-template>
  }

  <ng-template ng-label-tmp let-item="item">
    <span class="item" *ngIf="item">{{ item.label }}</span>
  </ng-template>

  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <span class="ng-select-multiple-value" *ngIf="multiple && items?.length === 1">
      {{ items[0].label }}
    </span>

    <span class="ng-select-multiple-value" *ngIf="multiple && items?.length > 1">
      <span>{{ items.length }} selected</span>
    </span>

    <span class="ng-value" *ngIf="!multiple && items?.length">
      {{ items[0].label }}
    </span>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
    @if (item && !showUserDetails) {
      <div class="wrapper item-no-details">
      @if (showCheckboxes && multiple) {
        <span class="form-check">
          <input class="form-check-input" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected">
          <label class="form-check-label" for="item-{{index}}">{{ item.label }}</label>
        </span>
      } @else {
        <div *ngIf="item" id="{{ item.id ?? 'id_' + index }}">{{ item.label }}</div>
      }
      </div>

    } @else {
      <div class="wrapper item-details" *ngIf="item && showUserDetails">
        <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>

        <div class="user-info">
          <p class="title" *ngIf="item">{{ item.label }}</p>
          <p class="sub-text" *ngIf="item?.job_title">{{ item.job_title }}</p>
        </div>
      </div>
    }
  </ng-template>
</ng-select>
